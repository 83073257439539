import React, { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { IoArrowForward } from "react-icons/io5";
import Calculator from "../Home/Calculator";
import { Link } from "react-router-dom";

const Save = () => {
  const { language } = useContext(LanguageContext);

  const translations = {
    en: {
      title: "Save on every transaction as your business grows.",
      desc: "Send money effortlessly with TKambio – fast, secure, and commission-free! Unlock the best exchange rates and seamless international payments, trusted by thousands of businesses worldwide. Your global transactions, simplified.",
      btn: "Calculate your savings",
    },
    es: {
      title: "Ahorre en cada transacción a medida que su negocio crece.",
      desc: "Envíe dinero sin esfuerzo con TKambio: ¡rápido, seguro y sin comisiones! Desbloquee los mejores tipos de cambio y pagos internacionales fluidos, en los que confían miles de empresas en todo el mundo. Sus transacciones globales, simplificadas.",
      btn: "Calcula tus ahorros",
    },
  };

  const t = translations[language];
  return (
    <div className="mt-[144px] max-w-[1200px] mx-auto">
      <div className="md:flex items-center gap-4">
        <div>
          <h1 className="text-5xl font-semibold text-primary">{t.title}</h1>
          <p className="text-textcolor/60 mt-8">{t.desc}</p>
          <button className="bg-secondary py-3 px-9 rounded-full text-white flex items-center my-6">
            <Link className="flex gap-3 items-center" target="_blank" to={process.env.REACT_APP_SCHEDULE_DEMO}>{t.btn}
            <IoArrowForward /></Link>
          </button>
        </div>
        <div className="w-full"><Calculator/></div>
      </div>
    </div>
  );
};

export default Save;
