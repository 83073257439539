import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { Navigation, EffectCoverflow,Autoplay } from "swiper/modules";
import { Link } from 'react-router-dom';
import CombioA from '../../../../assets/CambioDivisasA.webp'
import CombioB from '../../../../assets/CambioDivisasB.webp'
import InternacionalesA from '../../../../assets/InternacionalesA.webp'
import InternacionalesB from '../../../../assets/InternacionalesB.webp'
import MultidivisasA from '../../../../assets/MultidivisasA.webp'
import MultidivisasB from '../../../../assets/MultidivisasB.webp'
import ForwardsA from '../../../../assets/ForwardsA.webp'
import ForwardsB from '../../../../assets/ForwardsB.webp'
import MarketOrdersA from '../../../../assets/MarketOrdersA.webp'
import MarketOrdersB from '../../../../assets/MarketOrdersB.webp'

const slides = [
    {
      title: "Cambio de divisas",
      imgA: CombioA,
      imgB: CombioB,
      link: "/cambio-divisas",
    },
    {
      title: "Pagos internacionales",
      imgA: InternacionalesA,
      imgB: InternacionalesB,
      link: "/pagos-internacionales",
    },
    {
      title: "Cuenta Multidivisas",
      imgA: MultidivisasA,
      imgB: MultidivisasB,
      link: "/cuenta-multidivisas",
    },
    {
      title: "Forwards",
      imgA: ForwardsA,
      imgB: ForwardsB,
      link: "/forwards",
    },
    {
      title: "Market Orders",
      imgA: MarketOrdersA,
      imgB: MarketOrdersB,
      link: "/market-orders",
    },
  ];

const CurrencySolution = () => {
  return (
    <div className='max-w-[1300px] mx-auto bg-[#EBEEF6] py-8 rounded-3xl my-28'>
        <h1 className="text-4xl text-primary font-semibold text-center mb-9">Tenemos las soluciones de divisas que <br /> tu empresa necesita</h1>
        <div className="max-w-5xl mx-auto  rounded-lg">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"1"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        breakpoints={{
            700:{
                slidesPerView:3
            }
        }}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        navigation={true}
        modules={[Navigation, Autoplay, EffectCoverflow]}
        className="rounded-3xl"
      >
        {slides.map((slide, index) => (
          <SwiperSlide
            key={index}
            className="rounded-3xl overflow-hidden shadow-2xl group relative"
          >
            <div className="">
              <img
                src={slide.imgB}
                alt={slide.title}
                className="h-full w-full object-cover group-hover:hidden"
              />
              <img
                src={slide.imgA}
                alt={slide.title}
                className="h-full w-full object-cover hidden group-hover:block"
              />
            </div>
            <div className="absolute z-50 bottom-12 w-full flex flex-col justify-center items-center">
              <p className="md:text-4xl text-xl font-bold text-white text-center leading-relaxed">
                {slide.title}
              </p>
              <Link to={slide.link} rel="noopener noreferrer">
                <button className="mt-4 bg-secondary md:px-6 px-4 md:py-2 py-1 md:text-base text-sm text-white rounded-lg">
                  Saber más
                </button>
              </Link>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
    </div>
  )
}

export default CurrencySolution
