import React, { useEffect } from 'react'
import Hero from '../components/MultiCurrency/Hero'
import Simplify from '../components/MultiCurrency/Simplify'
import CurrencyProfile from '../components/MultiCurrency/CurrencyProfile'
import FAQs from '../components/MultiCurrency/FAQs'
import AOS from 'aos';  // Import AOS
import 'aos/dist/aos.css';

const MultiCurrency = () => {
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 500, 
      once:true,
      mirror: true, 
    });
  }, []);

  const sections = [<Hero/>,<Simplify/>,<CurrencyProfile/>,<FAQs/>]

  return (
    <div className=''>
{sections.map((sec,index)=>{
        return(
          <div data-aos="fade-up" data-aos-delay={`${index * 100}`}>{sec}</div>
        )
      })}
    </div>
  )
}

export default MultiCurrency
