import React, {  useEffect } from 'react'

const Chatbot = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://cdn.botpress.cloud/webchat/v1/inject.js'
    script.async = true
    document.body.appendChild(script)


    script.onload = () => {
      window.botpressWebChat.init({
        composerPlaceholder: '¿Tienes alguna duda?',
        botId: '10e845dc-6b70-4053-a6b8-9cf9035e185c',
        hostUrl: 'https://cdn.botpress.cloud/webchat/v1',
        messagingUrl: 'https://messaging.botpress.cloud',
        clientId: '10e845dc-6b70-4053-a6b8-9cf9035e185c',
        botName: 'Wendy TKambioUS',
        botConversationDescription: 'Asesor Financiero TKambioUS',
        avatarUrl: 'https://i.postimg.cc/c41nPct0/Frame-34614637.png',
        enableConversationDeletion: true,
        showPoweredBy: false,
        containerWidth: '100%25',
        layoutWidth: '100%25',
        showCloseButton: true,
        disableAnimations: false,
        closeOnEscape: false,
        showConversationsButton: false,
        enableTranscriptDownload: false,
        stylesheet: 'https://webchat-styler-css.botpress.app/prod/code/b2ec9130-496e-4f91-9183-4ee5086ef185/v72545/style.css'
      })
    }
  }, [])

  return (
    <div className='max-h-[500px] relative'>
      <div id="webchat" />
    </div>
  )
}

export default Chatbot