import React from 'react'
import icon1 from '../../../../assets/AHORROS.webp'
import icon2 from '../../../../assets/DIVISAS.webp'
import icon3 from '../../../../assets/SIN-COMISIONES.webp'
import icon4 from '../../../../assets/ILIMITADO.webp'
import icon5 from '../../../../assets/MANTENIMIENTO.webp'
import icon6 from '../../../../assets/SEGUIMIENTO.webp'


const features = [
    {
        icon:icon1,
        desc:"Experimenta ahorros significativos",
    },
    {
        icon:icon2,
        desc:"Cambia divisas el mismo día, día siguiente y 2 días",
    },
    {
        icon:icon3,
        desc:"Sin cobro por comisiones",
    },
    {
        icon:icon4,
        desc:"Operaciones ilimitadas",
    },
    {
        icon:icon5,
        desc:"Sin cobro por mantenimiento",
    },
    {
        icon:icon6,
        desc:"Seguimiento de pagos 24/7",
    },
]

const Features = () => {
  return (
    <div className='max-w-[1300px] mx-auto my-8'>
      <div className="grid md:px-0 px-4 md:grid-cols-3 gap-9">
        {features.map((item,index)=>{
            return(
                <div key={index} className='bg-white flex flex-col items-center text-center p-12 rounded-lg hover:bg-[#EBEEF6] duration-300 cursor-pointer gap-3 shadow-lg hover:-translate-y-1'>
                    <img className='w-[5rem]' src={item.icon} alt={item.desc} />
                    <p>{item.desc}</p>
                </div>
            )
        })}
      </div>
      
    </div>
  )
}

export default Features
