import { useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import "react-phone-input-2/lib/style.css"; // Import styles for react-phone-input-2
import PhoneInput from "react-phone-input-2";
const Registration = () => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        email: "",
        name: "",
        company: "",
        country: "",
        phone: "",
        tradeName: "",
        phoneNumber: "",
        state: "",
        city: "",
        zipCode: "",
        website: "",
        taxid: "",
        startDate: "",
        companyType: "",
        transactionTarget: "",
        transactionVolume: "",
        transactionNumber: "",
        requiredCurrency: "",
        paymentMethod:"",
        bankName: "",
        bankAdress: "",
        accountNumber: "",
        abaNumberRouteCode: "",
        accountType: "",
        bankFullName: "",
        positiionInCompany: "",
        phoneNumbernew: "",
        emailnew: "",
    });

    const handleNext = () => setStep((prev) => prev + 1);
    const handlePrev = () => setStep((prev) => prev - 1);
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        try {
            await axios.post("http://localhost/api/register.php", formData);
            alert("Registration successful!");
        } catch (error) {
            console.error("Registration failed", error);
        }
    };

    const progress = (step / 6) * 100;

    return (
        <div className="py-28 bg-gray-100">
            <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-white p-6 rounded-lg shadow-md w-[1300px] mx-auto"
            >
                {/* Progress Bar */}
                <div className="relative h-2 bg-gray-300 rounded-full mb-4">
                    <div
                        style={{ width: `${progress}%` }}
                        className="absolute h-2 bg-blue-500 rounded-full"
                    ></div>
                </div>
                <p className="text-sm text-gray-600 mb-4">Step {step} of 6</p>

                {/* Step Content */}
                {step === 1 && (
                    <div>
                        <div>
                            <h2 className="text-md font- mb-2">Email</h2>
                            <input
                                type="email"
                                name="email"
                                placeholder="Enter your email"
                                value={formData.email}
                                onChange={handleChange}
                                className="border border-gray-300 rounded-md p-2 w-full mb-4"
                            />
                        </div>
                        <div>
                            <h2 className="text-md font- mb-2">Full Name</h2>
                            <input
                                type="text"
                                name="name"
                                placeholder="Enter your name"
                                value={formData.name}
                                onChange={handleChange}
                                className="border border-gray-300 rounded-md p-2 w-full mb-4"
                            />
                        </div>
                        <div>
                            <h2 className="text-md font- mb-2">Company</h2>
                            <input
                                type="text"
                                name="company"
                                placeholder="Enter company name"
                                value={formData.company}
                                onChange={handleChange}
                                className="border border-gray-300 rounded-md p-2 w-full mb-4"
                            />
                        </div>
                        <div className="flex gap-6">
                            {/* Country Dropdown */}
                            <div className="w-full">
                                <h2 className="text-md font-bold mb-2">Country</h2>
                                <select
                                    name="country"
                                    value={formData.country}
                                    onChange={handleChange}
                                    className="border border-gray-300 rounded-md p-2 w-full mb-4"
                                >
                                    <option value="" disabled>
                                        Select a Country
                                    </option>
                                    <option value="United States">United States</option>
                                    <option value="Mexico">Mexico</option>
                                    <option value="Global">Global</option>
                                </select>
                            </div>

                            {/* Phone Number with Country Code */}
                            <div className="w-full">
                                <h2 className="text-md font-bold mb-2">Phone Number</h2>
                                <PhoneInput
                                    country={"us"} // Default country
                                    value={formData.phone}
                                    // name="phone"
                                    onChange={(phone) => setFormData({ ...formData, phone })}
                                    inputClass="w-full border border-gray-300 rounded-md p-2 mb-4"
                                />
                            </div>
                        </div>
                        <button
                            onClick={handleNext}
                            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                        >
                            Next
                        </button>
                    </div>
                )}
                {step === 2 && (
                    <div>
                        <div>
                            <h2 className="text-md mb-2">Company Name</h2>
                            <input
                                type="text"
                                name="company"
                                placeholder="Company Name"
                                value={formData.company}
                                onChange={handleChange}
                                className="border border-gray-300 rounded-md p-2 w-full mb-4"
                            />
                        </div>
                        <div>
                            <h2 className="text-md mb-2">Trade Name</h2>
                            <input
                                type="text"
                                name="tradeName"
                                placeholder="Trade Name"
                                value={formData.tradeName}
                                onChange={handleChange}
                                className="border border-gray-300 rounded-md p-2 w-full mb-4"
                            />
                        </div>
                        <div className="flex gap-6 items-center">
                            <div className="w-full">
                                <h2 className="text-md mb-2">State</h2>
                                <input
                                    type="text"
                                    name="state"
                                    placeholder="State Name"
                                    value={formData.state}
                                    onChange={handleChange}
                                    className="border border-gray-300 rounded-md p-2 w-full mb-4"
                                />
                            </div>
                            <div className="w-full">
                                <h2 className="text-md mb-2">City</h2>
                                <input
                                    type="text"
                                    name="city"
                                    placeholder="City Name"
                                    value={formData.city}
                                    onChange={handleChange}
                                    className="border border-gray-300 rounded-md p-2 w-full mb-4"
                                />
                            </div>
                        </div>
                        <div className="flex gap-6 items-center">
                            <div className="w-full">
                                <h2 className="text-md mb-2">Zip Code</h2>
                                <input
                                    type="text"
                                    name="zipCode"
                                    placeholder="Zip Code"
                                    value={formData.zipCode}
                                    onChange={handleChange}
                                    className="border border-gray-300 rounded-md p-2 w-full mb-4"
                                />
                            </div>
                            <div className="w-full">
                                <h2 className="text-md mb-2">Phone Number</h2>
                                <PhoneInput
                                    country={"us"} // Default country
                                    value={formData.phoneNumber}
                                    onChange={(phone) => setFormData({ ...formData, phone })}
                                    inputClass="w-full border border-gray-300 rounded-md p-2 mb-4"
                                />
                            </div>
                        </div>
                        <div className="flex gap-6 items-center">
                            <div className="w-full">
                                <h2 className="text-md mb-2">Website</h2>
                                <input
                                    type="text"
                                    name="website"
                                    placeholder="website"
                                    value={formData.website}
                                    onChange={handleChange}
                                    className="border border-gray-300 rounded-md p-2 w-full mb-4"
                                />
                            </div>
                            <div className="w-full">
                                <h2 className="text-md mb-2">Tax ID</h2>
                                <input
                                    type="text"
                                    name="taxid"
                                    placeholder="Tax ID"
                                    value={formData.taxid}
                                    onChange={handleChange}
                                    className="border border-gray-300 rounded-md p-2 w-full mb-4"
                                />
                            </div>
                        </div>

                        <div className="w-full">
                            <h2 className="text-md mb-2">Start Date</h2>
                            <input
                                type="date"
                                name="startDate"
                                placeholder="Date"
                                value={formData.startDate}
                                onChange={handleChange}
                                className="border border-gray-300 rounded-md p-2 w-full mb-4"
                            />
                        </div>
                        <div className="w-full">
                            <h2 className="text-md mb-2">Company Type</h2>
                            <div className="space-y-2 mb-4 text-gray-500">
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="radio"
                                        name="companyType"
                                        value="individual / single member llc"
                                        onChange={handleChange}
                                        className="border-gray-300 text-blue-600 focus:ring-blue-500"
                                    />
                                    <span>Individual / Single Member LLC</span>
                                </label>
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="radio"
                                        name="companyType"
                                        value="C corporation"
                                        onChange={handleChange}
                                        className="border-gray-300 text-blue-600 focus:ring-blue-500"
                                    />
                                    <span>C Corporation</span>
                                </label>
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="radio"
                                        name="companyType"
                                        value="S corporation"
                                        onChange={handleChange}
                                        className="border-gray-300 text-blue-600 focus:ring-blue-500"
                                    />
                                    <span>S Corporation</span>
                                </label>
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="radio"
                                        name="companyType"
                                        value="association"
                                        onChange={handleChange}
                                        className="border-gray-300 text-blue-600 focus:ring-blue-500"
                                    />
                                    <span>Association</span>
                                </label>
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="radio"
                                        name="companyType"
                                        value="trust/estate"
                                        onChange={handleChange}
                                        className="border-gray-300 text-blue-600 focus:ring-blue-500"
                                    />
                                    <span>Trust/Estate</span>
                                </label>
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="radio"
                                        name="companyType"
                                        value="no option applies"
                                        onChange={handleChange}
                                        className="border-gray-300 text-blue-600 focus:ring-blue-500"
                                    />
                                    <span>No Option Applies</span>
                                </label>
                            </div>
                        </div>


                        <div className="flex justify-between">
                            <button
                                onClick={handlePrev}
                                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                            >
                                Back
                            </button>
                            <button
                                onClick={handleNext}
                                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                )}
                {step === 3 && (
                    <div>
                        <div>
                            <h2 className="text-md mb-2">transaction target (specifier)</h2>
                            <input
                                type="text"
                                name="transactionTarget"
                                placeholder="Transaction Target"
                                value={formData.transactionTarget}
                                onChange={handleChange}
                                className="border border-gray-300 rounded-md p-2 w-full mb-4"
                            />
                        </div>
                        <div>
                            <h2 className="text-md mb-2">Annual Transaction volume (en:USD)</h2>
                            <input
                                type="number"
                                name="transactionVolume"
                                placeholder="Anual Transaction Volume"
                                value={formData.transactionVolume}
                                onChange={handleChange}
                                className="border border-gray-300 rounded-md p-2 w-full mb-4"
                            />
                        </div>
                        <div>
                            <h2 className="text-md mb-2">Annual Number of transaction</h2>
                            <input
                                type="number"
                                name="transactionNumber"
                                placeholder="Anual Number of Transaction"
                                value={formData.transactionNumber}
                                onChange={handleChange}
                                className="border border-gray-300 rounded-md p-2 w-full mb-4"
                            />
                        </div>
                        <div>
                            <h2 className="text-md mb-2">Required Currency</h2>
                            <select
                                name="requiredCurrency"
                                value={formData.requiredCurrency}
                                onChange={handleChange}
                                className="border border-gray-300 rounded-md p-2 w-full mb-4"
                            >
                                <option value="" disabled>
                                    Select a Country
                                </option>
                                <option value="USD">USD</option>
                                <option value="MXN">MXN Pesos</option>
                            </select>
                        </div>
                        <div className="flex justify-between">
                            <button
                                onClick={handlePrev}
                                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                            >
                                Back
                            </button>
                            <button
                                onClick={handleNext}
                                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                )}
                {step === 4 && (
                    <div>
                        <div>
                            <h2 className="text-md mb-2">Preferred Payment Method</h2>
                            <div className="space-y-2 mb-4 text-gray-500">
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="radio"
                                        name="paymentMethod"
                                        value="ACH direct debit"
                                        onChange={handleChange}
                                        className="border-gray-300 text-blue-600 focus:ring-blue-500"
                                    />
                                    <span>ACH Direct Debit</span>
                                </label>
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="radio"
                                        name="paymentMethod"
                                        value="Cheque"
                                        onChange={handleChange}
                                        className="border-gray-300 text-blue-600 focus:ring-blue-500"
                                    />
                                    <span>Cheque</span>
                                </label>
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="radio"
                                        name="paymentMethod"
                                        value="Transfer"
                                        onChange={handleChange}
                                        className="border-gray-300 text-blue-600 focus:ring-blue-500"
                                    />
                                    <span>Transfer</span>
                                </label>
                                <label className="flex items-center space-x-2">
                                    <input
                                        type="radio"
                                        name="paymentMethod"
                                        value="Not applicable"
                                        onChange={handleChange}
                                        className="border-gray-300 text-blue-600 focus:ring-blue-500"
                                    />
                                    <span>Not Applicable</span>
                                </label>
                            </div>

                            {/* Conditionally render bank details input */}
                            {(formData.paymentMethod === "ACH direct debit" ||
                                formData.paymentMethod === "Cheque") && (
                                    <div className="space-y-4">
                                        <div className="flex gap-6">
                                            <div className="w-full">
                                                <label className="text-md mb-2 block">Bank Name</label>
                                                <input
                                                    type="text"
                                                    name="bankName"
                                                    placeholder="Enter bank name"
                                                    value={formData.bankName || ""}
                                                    onChange={handleChange}
                                                    className="border border-gray-300 rounded-md p-2 w-full mb-2"
                                                />
                                            </div>
                                            <div className="w-full">
                                                <label className="text-md mb-2 block">Bank Address (city, states)</label>
                                                <input
                                                    type="text"
                                                    name="bankAdress"
                                                    placeholder="Enter bank adress"
                                                    value={formData.bankAdress || ""}
                                                    onChange={handleChange}
                                                    className="border border-gray-300 rounded-md p-2 w-full mb-2"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex gap-6">
                                            <div className="w-full">
                                                <label className="text-md mb-2 block">Account Number</label>
                                                <input
                                                    type="text"
                                                    name="accountNumber"
                                                    placeholder="Account Number"
                                                    value={formData.accountNumber || ""}
                                                    onChange={handleChange}
                                                    className="border border-gray-300 rounded-md p-2 w-full mb-2"
                                                />
                                            </div>
                                            <div className="w-full">
                                                <label className="text-md mb-2 block">ABA number / Route code</label>
                                                <input
                                                    type="text"
                                                    name="abaNumberRouteCode"
                                                    placeholder="ABA number / Route code"
                                                    value={formData.abaNumberRouteCode || ""}
                                                    onChange={handleChange}
                                                    className="border border-gray-300 rounded-md p-2 w-full mb-2"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <h2 className="text-md mb-2">Account Type</h2>
                                            <label className="flex items-center space-x-2 text-gray-500">
                                                <input
                                                    type="radio"
                                                    name="accountType"
                                                    value="Checking"
                                                    onChange={handleChange}
                                                    className="border-gray-300 text-blue-600 focus:ring-blue-500"
                                                />
                                                <span>Checking</span>
                                            </label>
                                            <label className="flex items-center space-x-2 text-gray-500">
                                                <input
                                                    type="radio"
                                                    name="accountType"
                                                    value="Saving"
                                                    onChange={handleChange}
                                                    className="border-gray-300 text-blue-600 focus:ring-blue-500"
                                                />
                                                <span>Saving</span>
                                            </label>
                                        </div>
                                    </div>
                                )}
                        </div>

                        <div className="flex justify-between mt-7">
                            <button
                                onClick={handlePrev}
                                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                            >
                                Back
                            </button>
                            <button
                                onClick={handleNext}
                                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                )}
                {step === 5 && (
                    <div>
                        <div className="w-full">
                            <label className="text-md mb-2 block">Full Name</label>
                            <input
                                type="text"
                                name="bankFullName"
                                placeholder="Enter Full Name"
                                value={formData.bankFullName}
                                onChange={handleChange}
                                className="border border-gray-300 rounded-md p-2 w-full mb-2"
                            />
                        </div>
                        <div className="w-full">
                            <label className="text-md mb-2 block">Position in Company</label>
                            <input
                                type="text"
                                name="positiionInCompany"
                                placeholder="Enter position in company"
                                value={formData.positiionInCompany}
                                onChange={handleChange}
                                className="border border-gray-300 rounded-md p-2 w-full mb-2"
                            />
                        </div>
                        <div className="flex gap-6">
                        <div className="w-full">
                            <label className="text-md mb-2 block">Phone Number</label>
                            <PhoneInput
                                country={"us"} // Default country
                                placeholder="Enter position in company"
                                value={formData.phoneNumbernew}
                                onChange={(phone) => setFormData({ ...formData, phone })}
                                inputClass="border border-gray-300 rounded-md p-2 w-full mb-2"
                            />
                        </div>
                        <div className="w-full">
                            <label className="text-md mb-2 block">Email</label>
                            <input
                                type='email' // Default country
                                name="emailnew"
                                placeholder="Enter email"
                                value={formData.emailnew}
                                onChange={handleChange}
                                className="border border-gray-300 rounded-md p-2 w-full mb-2"
                            />
                        </div>
                        </div>
                        <div className="flex justify-between mt-7">
                            <button
                                onClick={handlePrev}
                                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                            >
                                Back
                            </button>
                            <button
                                onClick={handleNext}
                                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                )}
                {step === 6 && (
                    <div>
                        <h2 className="text-xl font-bold mb-4">Step 4: Confirm</h2>
                        <div className="grid grid-cols-4 gap-4">
                        <p className="text-primary"><b>Email: </b>{formData.email}</p>
                        <p className="text-primary"><b>Name: </b>{formData.name}</p>
                        <p className="text-primary"><b>Company: </b>{formData.company}</p>
                        <p className="text-primary"><b>Country: </b>{formData.country}</p>
                        <p className="text-primary"><b>Phone: </b>{formData.phone}</p>
                        <p className="text-primary"><b>Trade Name: </b>{formData.tradeName}</p>
                        <p className="text-primary"><b>Phone Number: </b>{formData.phoneNumber}</p>
                        <p className="text-primary"><b>State: </b>{formData.state}</p>
                        <p className="text-primary"><b>City: </b>{formData.city}</p>
                        <p className="text-primary"><b>Zip Code: </b>{formData.zipCode}</p>
                        <p className="text-primary"><b>Website: </b>{formData.website}</p>
                        <p className="text-primary"><b>Tax ID: </b>{formData.taxid}</p>
                        <p className="text-primary"><b>Start Date: </b>{formData.startDate}</p>
                        <p className="text-primary"><b>Company Type: </b>{formData.companyType}</p>
                        <p className="text-primary"><b>Transaction Target: </b>{formData.transactionTarget}</p>
                        <p className="text-primary"><b>Transactoin Volume: </b>{formData.transactionVolume}</p>
                        <p className="text-primary"><b>Number of Transactions: </b>{formData.transactionNumber}</p>
                        <p className="text-primary"><b>Required Currency: </b>{formData.requiredCurrency}</p>
                        <p className="text-primary"><b>Payment Method: </b>{formData.paymentMethod}</p>
                        <p className="text-primary"><b>Bank Name: </b>{formData.bankName}</p>
                        <p className="text-primary"><b>Bank Adress: </b>{formData.bankAdress}</p>
                        <p className="text-primary"><b>Account Number: </b>{formData.accountNumber}</p>
                        <p className="text-primary"><b>ABA / Routing NUMBER: </b>{formData.abaNumberRouteCode}</p>
                        <p className="text-primary"><b>Account Type: </b>{formData.accountType}</p>
                        <p className="text-primary"><b>Full Name on Bank: </b>{formData.bankFullName}</p>
                        <p className="text-primary"><b>Postition in Company: </b>{formData.positiionInCompany}</p>
                        <p className="text-primary"><b>Phone Number: </b>{formData.phoneNumbernew}</p>
                        <p className="text-primary"><b>Email: </b>{formData.emailnew}</p>
                        </div>
                        {/* <p>{formData}</p> */}
                        <div className="flex justify-between">
                            <button
                                onClick={handlePrev}
                                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                            >
                                Back
                            </button>
                            <button
                                onClick={handleSubmit}
                                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                )}
            </motion.div>
        </div>
    );
};

export default Registration;
