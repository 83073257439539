import React, { useContext, useState } from "react";
import { motion } from "framer-motion";
import { IoIosArrowDown } from "react-icons/io";
import { LanguageContext } from '../context/LanguageContext';

const translations = {
    en: [
        {
            question: "What is TKambio?",
            answer:
                "TKambio is a financial platform that offers currency exchange and international payment solutions tailored to your business needs.",
        },
        {
            question: "Who can register?",
            answer:
                "Individuals with a business regime or legal entities.",
        },
        {
            question: "Do I need a dollar account?",
            answer: "Yes, it is necessary to have an account in dollars and another in soles to be able to carry out an exchange transaction, whether buying or selling dollars."
        },
        {
            question: "Do you work with banks other than BCP or Interbank?",
            answer: "We work directly and nationwide with accounts at BCP and Interbank banks, and with other banks through deferred interbank transfers, only if they are from Lima."
        },
        {
            question: "Can I carry out an exchange transaction as a company?",
            answer: "Yes, you can operate as a company; you must first register as a natural person and then register the company."
        },
        {
            question: "Can my transaction funds come from a friend's and/or family member's account?",
            answer: "No. The account from which the transaction originates must belong to the user registered on the platform."
        },
        {
            question: "Can the destination account for my transaction belong to a third party?",
            answer: "Yes, keep in mind that when registering the bank account you must enter the third party's details."
        },
        {
            question: "Do you charge a fee for using the service?",
            answer: "No, TKambio does not charge any fees."
        },
        {
            question: "Do you accept cash deposits?",
            answer: "No, we do not accept cash deposits. We only accept bank transfers between accounts."
        },
        {
            question: "What does time-based/deferred interbank transfer mean?",
            answer: "Transfers to bank accounts other than BCP and Interbank may take between 24-48 business hours."
        },
        {
            question: "What type of currency do you work with?",
            answer: "At the moment we only work with soles and US dollars."
        },
        {
            question: "What are your opening hours?",
            answer: "Regular hours: Monday to Friday from 9am to 6pm. Extended hours: Monday to Friday from 6pm to 7pm - Saturdays from 9am to 2pm."
        },
        {
            question: "What does it mean that they are not extended hours?",
            answer: "Before registering any transaction, check the availability of funds through the online chat on our platform."
        },
        {
            question: "My Bank accounts are not verified, can I still perform transactions?",
            answer: "Your bank accounts will be verified later as you generate transactions on our website. This is not an inconvenience for the registration of your transaction, you can operate without any problem."
        },
        {
            question: "If I transfer the amount plus commission and want to cancel later, will I get a refund of the total amount I transferred?",
            answer: "If you transfer from BCP or Interbank, the full amount (amount + commission) would be refunded. If it were from a bank other than BCP and Interbank, only the amount would be refunded, since the commission would be affected by the interbank transfer that would be made for the refund."
        },
        {
            question: "What are TK-OFFERS?",
            answer: "The TK-Offers published on our website offer you a 'Super Exchange Rate.' The benefit they provide is that you will be able to carry out transactions with the best exchange rate on the market. These campaigns present conditions, such as the bank, the exchange rate, the minimum amount to access them, the available stock, and a time limit. These will appear in the calculator at the time of quoting. Likewise, these types of offers are until the delimited time ends or stock runs out."
        },
        {
            question: "Is there a minimum or maximum amount to make a transaction?",
            answer: "No, we currently do not have minimum or maximum amounts for performing a transaction. Please take into account the transfer limits established by your bank."
        },
        {
            question: "Can I pay my credit card?",
            answer: "Yes, with the TKambio service you can pay BCP credit cards."
        },
    ],
    es: [
        {
            question: "¿Qué es TKambio?",
            answer: "TKambio es una plataforma financiera que ofrece soluciones de cambio de divisas y pagos internacionales adaptadas a las necesidades de su negocio."
        },
        {
            question: "¿Quién puede registrarse?",
            answer: "Personas con régimen empresarial o entidades legales."
        },
        {
            question: "¿Necesito una cuenta en dólares?",
            answer: "Sí, es necesario tener una cuenta en dólares y otra en soles para poder realizar una transacción de cambio, ya sea comprando o vendiendo dólares."
        },
        {
            question: "¿Trabajan con bancos distintos a BCP o Interbank?",
            answer: "Trabajamos directamente y a nivel nacional con cuentas en los bancos BCP e Interbank, y con otros bancos a través de transferencias interbancarias diferidas, solo si son de Lima."
        },
        {
            question: "¿Puedo realizar una transacción de cambio como empresa?",
            answer: "Sí, puedes operar como empresa; primero debes registrarte como persona natural y luego registrar la empresa."
        },
        {
            question: "¿Pueden los fondos de mi transacción provenir de la cuenta de un amigo o familiar?",
            answer: "No. La cuenta desde la cual se origina la transacción debe pertenecer al usuario registrado en la plataforma."
        },
        {
            question: "¿La cuenta de destino de mi transacción puede pertenecer a un tercero?",
            answer: "Sí, ten en cuenta que al registrar la cuenta bancaria debes ingresar los datos del tercero."
        },
        {
            question: "¿Cobran alguna tarifa por usar el servicio?",
            answer: "No, TKambio no cobra ninguna tarifa."
        },
        {
            question: "¿Aceptan depósitos en efectivo?",
            answer: "No, no aceptamos depósitos en efectivo. Solo aceptamos transferencias bancarias entre cuentas."
        },
        {
            question: "¿Qué significa transferencia interbancaria diferida o basada en tiempo?",
            answer: "Las transferencias a cuentas bancarias distintas a BCP e Interbank pueden tardar entre 24-48 horas hábiles."
        },
        {
            question: "¿Con qué tipo de moneda trabajan?",
            answer: "Por el momento solo trabajamos con soles y dólares estadounidenses."
        },
        {
            question: "¿Cuáles son sus horarios de atención?",
            answer: "Horario regular: de lunes a viernes de 9am a 6pm. Horario extendido: de lunes a viernes de 6pm a 7pm - sábados de 9am a 2pm."
        },
        {
            question: "¿Qué significa que no sean horas extendidas?",
            answer: "Antes de registrar cualquier transacción, verifica la disponibilidad de fondos a través del chat en línea en nuestra plataforma."
        },
        {
            question: "Mis cuentas bancarias no están verificadas, ¿puedo realizar transacciones?",
            answer: "Tus cuentas bancarias se verificarán más adelante a medida que generes transacciones en nuestro sitio web. Esto no es un inconveniente para el registro de tu transacción, puedes operar sin ningún problema."
        },
        {
            question: "Si transfiero el monto más comisión y quiero cancelar más tarde, ¿recibiré un reembolso del monto total que transferí?",
            answer: "Si transfieres desde BCP o Interbank, se te reembolsará el monto total (monto + comisión). Si fuera desde un banco distinto a BCP e Interbank, solo se te reembolsará el monto, ya que la comisión se vería afectada por la transferencia interbancaria que se haría para el reembolso."
        },
        {
            question: "¿Qué son las TK-OFERTAS?",
            answer: "Las TK-Ofertas publicadas en nuestro sitio web te ofrecen un 'Súper Tipo de Cambio.' El beneficio que proporcionan es que podrás realizar transacciones con el mejor tipo de cambio del mercado. Estas campañas presentan condiciones, como el banco, el tipo de cambio, el monto mínimo para acceder a ellas, el stock disponible y un límite de tiempo. Estas aparecerán en el calculador al momento de cotizar. Asimismo, este tipo de ofertas están vigentes hasta que finalice el tiempo delimitado o se agote el stock."
        },
        {
            question: "¿Hay un monto mínimo o máximo para realizar una transacción?",
            answer: "No, actualmente no tenemos montos mínimos o máximos para realizar una transacción. Por favor, ten en cuenta los límites de transferencia establecidos por tu banco."
        },
        {
            question: "¿Puedo pagar mi tarjeta de crédito?",
            answer: "Sí, con el servicio de TKambio puedes pagar tarjetas de crédito de BCP."
        },
    ]
};


const FAQs = () => {
    const { language } = useContext(LanguageContext);
    const [openIndex, setOpenIndex] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [visibleCount, setVisibleCount] = useState(6); // Show 6 FAQs initially

    const t = translations[language];

    const filteredFAQs = t.filter(faq =>
        faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
        faq.answer.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const toggleFAQ = (index) => {
        setOpenIndex(index === openIndex ? null : index);
    };

    const loadMoreFAQs = () => {
        setVisibleCount((prevCount) => prevCount + 6); // Load 6 more FAQs on button click
    };

    return (
        <div className="max-w-4xl mx-auto rounded-t-2xl my-8">
            <div className="max-w-2xl mx-auto py-8 px-4">
                <h2 className="text-4xl font-medium text-primary mb-8 text-center">
                    {language === 'en' ? "Frequently Asked Questions" : "Preguntas frecuentes"}
                </h2>

                {/* Search Input */}
                <div className="mb-6">
                    <input
                        type="text"
                        placeholder={language === 'en' ? "Search FAQs..." : "Buscar en FAQs..."}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-lg"
                    />
                </div>

                <div className="space-y-4">
                    {filteredFAQs.slice(0, visibleCount).map((faq, index) => (
                        <div
                            key={index}
                            className="border border-gray-200 rounded-lg overflow-hidden shadow-sm"
                        >
                            {/* Question */}
                            <button
                                onClick={() => toggleFAQ(index)}
                                className="w-full flex justify-between items-center p-4 bg-gray-50 text-left"
                            >
                                <span className="font-medium">{faq.question}</span>
                                <motion.div
                                    initial={{ rotate: 0 }}
                                    animate={{ rotate: openIndex === index ? 180 : 0 }}
                                    transition={{ duration: 0.3 }}
                                    className="text-gray-500"
                                >
                                    <IoIosArrowDown />
                                </motion.div>
                            </button>

                            {/* Answer */}
                            <motion.div
                                initial={{ height: 0, opacity: 0 }}
                                animate={{
                                    height: openIndex === index ? "auto" : 0,
                                    opacity: openIndex === index ? 1 : 0,
                                }}
                                transition={{ duration: 0.5 }}
                                className="overflow-hidden bg-white"
                            >
                                <div className="p-4 text-gray-600">{faq.answer}</div>
                            </motion.div>
                        </div>
                    ))}
                </div>

                {/* Load More Button */}
                {visibleCount < filteredFAQs.length && (
                    <div className="text-center mt-6">
                        <button
                            onClick={loadMoreFAQs}
                            className="px-6 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors"
                        >
                            {language === 'en' ? "Load More" : "Cargar más"}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FAQs;
