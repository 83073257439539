const translations = {
    en:[
        {
            question: "What is TKambio?",
            answer:
                "TKambio is a financial platform that offers currency exchange and international payment solutions tailored to your business needs.",
        },
        {
            question: "Who can register?",
            answer:
                "Individuals with a business regime or legal entities.",
        },
        {
            question: "Do you charge for account opening at TKambio USA?",
            answer:
                "Having your company account with us is FREE.",
        },
        {
            question: "What bank are your funds in?",
            answer:
                "Our funds are located at UMB Bank, Kansas City.",
        },
        {
            question: "How do I register my company?",
            answer:
                "Enter the form on our website: https://tkambio.us/forms/ and fill in the data, after 3 days we will contact you to provide you with your account accesses and you can enjoy the best exchange rate.",
        },
        {
            question: "What documentation do i require to open an account with TKambio USA?",
            answer:
                "Articles of incorporation or commercial license, tax identification card, power of attorney, official identification of all partners and persons authorized to transact, proof of address and corporate client agreement.",
        },
    ],

    es:[
        {
            "question": "¿Qué es TKambio?",
            "answer": "TKambio es una plataforma financiera que ofrece soluciones de cambio de divisas y pagos internacionales adaptadas a las necesidades de su negocio."
        },
        {
            question: "¿Quiénes pueden registrarse?",
            answer:
                "Personas físicas con régimen empresarial o personas morales.",
        },
        {
            question: "¿Cobran por apertura de cuenta en TKambio USA?",
            answer:
                "Tener tu cuenta empresa con nosotros es GRATUITO.",
        },
        {
            question: "¿En qué banco se encuentran sus fondos?",
            answer:
                "Nuestros fondos se encuentran en UMB Bank, Kansas City.",
        },
        {
            question: "¿Cómo registro a mi empresa?",
            answer:
                "Ingresa al formulario de nuestra web: https://tkambio.us/forms/ y completa los datos, luego de 3 días nos contactaremos contigo para brindarte tus accesos de cuenta y puedas disfrutar del mejor tipo de cambio.",
        },
        {
            question: "¿Qué documentación solicitan para aperturar una cuenta en TKambio USA?",
            answer:
                "Acta constitutiva o licencia comercial, cédula de identificación fiscal, acta de poderes, identificación oficial de todos los socios y de las personas autorizadas para transaccionar, comprobante de domicilio y el corporate client agreement.",
        },
    ]
}

export default translations