import React, { useContext } from 'react'
import img from '../../assets/simon.png'
import { LanguageContext } from '../../context/LanguageContext';
const BirthofTkambio = () => {
  const { language } = useContext(LanguageContext);
  return (
    <div className='max-w-[1400px] md:mx-auto mx-4 md:my-[100px] my-[50px]'>
      <div className="flex md:flex-row flex-col justify-center md:gap-48 gap-5">
        <div className='flex-1'>
          <h3 className='text-dark md:text-[48px] text-[28px]'>{language === 'en' ? 'The  Birth of Tkambio USA' : 'El Nacimiento de Tkambio USA'}</h3>
          {language === 'en' ? (
            <div className='md:text-[18px] text-[16px] text-[#141B45B2] flex flex-col gap-3'>
              <p>Tkambio USA was co-founded by Simon Gomez, Marco Poma y Carlos  Valderrama. Motivated by the vision of a borderless world and his passion for simplifying and facilitating global trade. With the mission of creating a platform that allows international payments to be made in real-time at a low cost and in an easy way, Tkambio USA seeks to eliminate borders and enable seamless international trade through simple processes.</p>
              <p>Tkambio USA aims to help and facilitate companies to be successful by reducing their costs in foreign exchange, lowering the costs of international payments, and simplifying multi-currency transactions. The platform offers a single multi-currency account for the management of the global treasury of companies, helping to minimize the risk of financial losses due to exchange rate fluctuations.
              </p>
              <p>At Tkambio USA, we imagine a world without borders, where all micro and small companies can participate in international trade and have access to the same tools and cost advantages as large companies. We help them compete with low costs and straightforward payment management, enabling them to achieve prices and treasury tools similar to those of larger enterprises.
              </p>
            </div>
          ) : (
            <div className='md:text-[18px] text-[16px] text-[#141B45B2] flex flex-col gap-3'>
              <p>Tkambio USA fue co-fundada por Simon Gomez, Marco Poma y Carlos  Valderrama. Motivado por la visión de un mundo sin fronteras y su pasión por simplificar y facilitar el comercio global. Con la misión de crear una plataforma que permita realizar pagos internacionales en tiempo real a bajo costo y de una manera fácil, Tkambio USA busca eliminar las fronteras y permitir un comercio internacional sencillo a través de procesos simples.</p>
              <p>Tkambio USA tiene el propósito de ayudar y facilitar a las empresas a que sean exitosas, reduciendo sus costos en el cambio de divisas, facilitando y reduciendo los costos de los pagos internacionales, y simplificando las transacciones multidivisas. La plataforma ofrece una sola cuenta multidivisas para la administración de la tesorería global de las empresas, ayudando a minimizar el riesgo de pérdidas financieras debido a la variación del tipo de cambio.</p>
              <p>En Tkambio USA, imaginamos un mundo sin fronteras, donde todas las micro y pequeñas empresas puedan participar en el comercio internacional y contar con las mismas herramientas y costos que las grandes empresas. Les ayudamos a competir con costos bajos y una administración de pagos sencilla, permitiéndoles tener precios y herramientas de tesorería similares a las de las grandes empresas.</p>
            </div>
          )}
        </div>
        <div className=''>
          <img className='w-[400px]' src={img} alt="" />
        </div>
      </div>
    </div>
  )
}

export default BirthofTkambio
