import React, { useContext } from "react";
import herobg from "../assets/hero-bg.png";

import { IoArrowForward } from "react-icons/io5";
import { LanguageContext } from "../context/LanguageContext"; // Importing LanguageContext
import { Link } from "react-router-dom";

const MainHero = (props) => {
  const { language } = useContext(LanguageContext);

  // Translations for each language
  const translations = {
    en: {
      heading: props.enheading,
      description:props.endesc,
      getStarted:props.enbtn,
    },
    es: {
      heading: props.esheading,
      description:props.esdesc,
      getStarted: props.esbtn,
    },
  };

  return (
    <div
      style={{ backgroundImage: `url(${herobg})` }}
      className="w-full h-[90vh]"
    >
      <div className="flex flex-col items-center">
        <div className="w-[50%]">
        <h1 className="md:text-6xl sm:text-4xl text-2xl font-semibold text-center md:pt-9 pt-36 pb-8 leading-[1.2]">
          {translations[language].heading}
        </h1>
        </div>
        <div className="w-[50%]">
        <p className="font-medium md:text-2xl text-center text-textcolor/60">
          {translations[language].description}
        </p></div>
        <button className="bg-secondary py-3 px-9 rounded-full text-white flex items-center my-6">
          <Link to={process.env.REACT_APP_SCHEDULE_DEMO} className="flex gap-2 items-center">{translations[language].getStarted}
          <IoArrowForward /></Link>
        </button>
      </div>
      <img
        src={props.img}
        alt=""
        className="lg:block hidden w-full h-[500px] object-cover object-top"
      />
    </div>
  );
};

export default MainHero;
