import React from 'react'
import { IoArrowForward } from 'react-icons/io5'
import { Link } from 'react-router-dom'

const Card = (props) => {
  return (
    <div className='max-w-[1200px] md:mx-auto mx-3 md:flex items-center gap-9 my-28'>
      <div className='md:w-[654px]'>
        <h1 className="md:text-5xl text-4xl text-primary font-semibold">{props.title}</h1>
        <p className="mt-6 mb-4">{props.p}</p>
      <button className="bg-secondary py-3 px-9 rounded-full text-white my-6">
        <Link className='flex items-center gap-2' to={process.env.REACT_APP_REGISTER} target='_blank'>{props.btn} <IoArrowForward/></Link>
      </button>
      </div>
      <div className={`md:w-[532px] ${props.styles}`}>
        <img src={props.img} alt="" />
      </div>
    </div>
  )
}

export default Card
