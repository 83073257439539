import React, { useContext } from "react";
import 'swiper/css';
import 'swiper/css/navigation';

import quote from '../assets/quote.svg'
import translations from "../languageContent/TestimonialsContent";
import { LanguageContext } from "../context/LanguageContext";


const Testimonials = () => {
    const { language } = useContext(LanguageContext);
    const t = translations[language];

    return (
        <div className="max-w-[1300px] mx-auto my-16">
            <h1 className="text-3xl font-semibold text-center text-primary">{language==='en'?'Testimonials':'Testimonios'}</h1>
            <h1 className="text-lg mb-8 text-gray-600 text-center">
                {language === 'en' ? (
                    <>
                        Don't take our word for it, take theirs
                    </>
                ) : (
                    <>
                        No confíes en nuestra palabra para ello, toma el suyo
                    </>
                )}
            </h1>
            <div className="grid md:grid-cols-2 gap-4 grid-cols-1">
                {(t || []).map((testimonial, index) => (
                    <div key={index} className="bg-slate-400/20 rounded-lg p-4" >
                        <div className="flex flex-col justify-between">
                            <div className="flex justify-between items-center px-8">
                                <img
                                    src={testimonial.image}
                                    alt={testimonial.name}
                                />
                                <img src={quote} alt="" />
                            </div>
                            <p className="my-3 text-sm text-black/60">"{testimonial.feedback}"</p>
                            <h3 className="my-2 font-semibold text-black/80">{testimonial.name}</h3>
                            <p className="text-black/60">{testimonial.country}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Testimonials;
