import React, { useContext } from 'react'
import { LanguageContext } from '../../context/LanguageContext';

const Banner = () => {
    const {language} = useContext(LanguageContext);
  return (
    <div className='bg-primary py-6 text-white'>
      <div className="max-w-[1400px] md:mx-auto mx-4 flex md:flex-row flex-col md:text-left text-center md:gap-0 gap-6 justify-between items-center">
        <div className='flex flex-col items-center'>
            <h4 className='text-[#D8DFFF] text-[54px] font-semibold'>1,200+</h4>
            <h6 className='text-[32px]'>{language==='en'?'Registered Businesses':"Empresas registradas"}</h6>
        </div>
        <div className='flex flex-col items-center'>
            <h4 className='text-[#D8DFFF] text-[54px] font-semibold'>180+</h4>
            <h6 className='text-[32px]'>{language==='en'?'Global Reach':"Alcance global"}</h6>
        </div>
        <div className='flex flex-col items-center'>
            <h4 className='text-[#D8DFFF] text-[54px] font-semibold'>1,200M</h4>
            <h6 className='text-[32px]'>{language==='en'?'Dollar Exchanged':"dólar intercambiado"}</h6>
        </div>
        <div className='flex flex-col items-center'>
            <h4 className='text-[#D8DFFF] text-[54px] font-semibold'>98%</h4>
            <h6 className='text-[32px]'>{language==='en'?'Success Rate':"Tasa de éxito"}</h6>
        </div>
      </div>
    </div>
  )
}

export default Banner
