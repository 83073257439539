import React, { useContext } from 'react'
import { LanguageContext } from '../../context/LanguageContext'

const Hero = () => {
    const {language} = useContext(LanguageContext);
    const t = language === 'en' ? {
        aboutUs: 'About Us',
        drivingSeamlessGlobalFinance: 'Driving Seamless Global Finance for Businesses',
        tkambioSimplifiesCurrencyExchange: 'Tkambio simplifies currency exchange, empowering businesses to grow across borders with reliable, tailored solutions.'} : {
            aboutUs: 'Sobre Nosotros',
            drivingSeamlessGlobalFinance: 'Conduciendo Finanzas Globales Sin Fisuras para Empresas',
            tkambioSimplifiesCurrencyExchange: 'Tkambio simplifica el intercambio de divisas, empoderando a las empresas para crecer más allá de las fronteras con soluciones fiables y a medida.'
        }
    return (
        <div className='bg-[#D8DFFF] py-16 mb-4'>
            <div className='flex justify-center items-center'>
                <div className='text-[#50C878] bg-[#ECFFF266] text-center inline-block py-2 px-9 rounded-full border border-[#50C878]'>
                    {t.aboutUs}
                </div>
            </div>
            <div className='max-w-[1400px] mx-auto text-center font-medium text-dark'>
                <h2 className="md:text-[45px] text-[28px]">{t.drivingSeamlessGlobalFinance}</h2>
                <p className='text-[#202020B2] md:text-[28px] text-[14px] font-normal'>{t.tkambioSimplifiesCurrencyExchange}</p>
            </div>
        </div>

    )
}

export default Hero
