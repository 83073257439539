import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import blogs from '../languageContent/blogs';
import { IoTimeOutline } from 'react-icons/io5';
import { LanguageContext } from '../context/LanguageContext';

const Blog = () => {
    const { language } = useContext(LanguageContext);
    
    // Check if blogs[language] exists before accessing
    const blogList = (blogs[language] || []).slice().reverse();
    const lblog = blogs[language];

    // Get the last blog from the list
    const lastblogs = lblog[lblog.length - 1];

    // State for controlling the number of blogs to show
    const [visibleBlogs, setVisibleBlogs] = useState(8);

    const slugify = (text) => {
        return text.toString().toLowerCase()
            .replace(/\s+/g, '-')           // Replace spaces with -
            .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
            .replace(/\-\-+/g, '-')         // Replace multiple - with single -
            .replace(/^-+/, '')             // Trim - from start of text
            .replace(/-+$/, '');            // Trim - from end of text
    };

    const handleLoadMore = () => {
        setVisibleBlogs(prev => prev + 8); // Increase the number of visible blogs by 8
    };

    return (
        <div className="max-w-[1300px] mx-auto p-6">
            {lastblogs && (
                <Link to={`/blog/${lastblogs.id}/${slugify(lastblogs.title)}`}>
                    <div className='w-full h-[400px] bg-cover bg-center rounded-lg relative bg-no-repeat ' style={{ backgroundImage: `url(${lastblogs.img})` }}>
                        <div className="bg-black/40 absolute w-full z-1 h-[400px] rounded-lg"></div>
                        <div className="flex justify-end items-right flex-col h-full px-4 z-10 relative">
                            <div className='mb-4'>
                                <div className="bg-white text-primary inline-block py-1 px-4 rounded-full">
                                    Present
                                </div>
                                <h1 className="text-3xl text-white w-[550px] my-2">{lastblogs.title}</h1>
                                <div className='text-white flex items-center gap-1'>
                                    <IoTimeOutline className='text-2xl' />
                                    {lastblogs.date}
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            )}
            <div className="grid md:grid-cols-4 grid-cols-1 gap-4 my-[70px]">
                {blogList.slice(0, visibleBlogs).map((blog) => (
                    <div key={blog.id} className="border-b pb-4 bg-white shadow-lg rounded-lg">
                        <h2 className="text-xl font-semibold">
                            <Link to={`/blog/${blog.id}/${slugify(blog.title)}`}>
                                <div className='w-full h-[200px] overflow-hidden rounded-t-lg'>
                                    <img className='w-full hover:scale-110 duration-300 h-full object-cover object-center' src={blog.img} alt="" />
                                </div>
                                <div className="px-3 py-2">
                                    {blog.title}
                                </div>
                            </Link>
                        </h2>
                    </div>
                ))}
            </div>

            {visibleBlogs < blogList.length && (
                <button
                    onClick={handleLoadMore}
                    className="mx-auto flex justify-center py-2 px-4 bg-primary text-white rounded-full hover:bg-primary-dark duration-300"
                >
                    Load More
                </button>
            )}
        </div>
    );
};

export default Blog;
