import React, { useEffect, useState } from 'react';
import logo from '../../assets/logo-2.png';
import mxn from '../../assets/MXN.png';
import usd from '../../assets/USD.png';
import arrowchange from '../../assets/arrow-change.svg';
import { Link } from 'react-router-dom';
// import axios from 'axios';


const exchange_rate_mxn = [
  {
  "bankName": "Afirme",
  "buyPrice": "19.60",
  "salePrice": "21.20"
  },
  {
  "bankName": "Banco Azteca",
  "buyPrice": "19.55",
  "salePrice": "21.15"
  },
  {
  "bankName": "Banco de México, FIX",
  "buyPrice": "20.71",
  "salePrice": "20.71"
  },
  // {
  // "bankName": "Banco de México, Interbancario 48 hrs apertura",
  // "buyPrice": "20.609",
  // "salePrice": "20.62"
  // },
  // {
  // "bankName": "Banco de México, Interbancario 48 hrs máximo",
  // "buyPrice": "20.833",
  // "salePrice": "20.833"
  // },
  // {
  // "bankName": "Banco de México, Interbancario 48 hrs mínimo",
  // "buyPrice": "20.62",
  // "salePrice": "20.62"
  // },
  // {
  // "bankName": "Bank of America",
  // "buyPrice": "19.4553",
  // "salePrice": "21.7391"
  // },
  {
  "bankName": "Banorte",
  "buyPrice": "19.40",
  "salePrice": "20.95"
  },
  {
  "bankName": "BBVA Bancomer",
  "buyPrice": "19.75",
  "salePrice": "20.91"
  },
  {
  "bankName": "CIBanco",
  "buyPrice": "19.31",
  "salePrice": "19.31"
  },
  {
  "bankName": "Citibanamex",
  "buyPrice": "20.07",
  "salePrice": "21.22"
  },
  // {
  // "bankName": "DOF, Diario Oficial de la Federación",
  // "buyPrice": "20.29",
  // "salePrice": "20.29"
  // },
  {
  "bankName": "Grupo Financiero Multiva",
  "buyPrice": "20.31",
  "salePrice": "20.31"
  },
  {
  "bankName": "Inbursa",
  "buyPrice": "20.25",
  "salePrice": "21.25"
  },
  {
  "bankName": "Intercam",
  "buyPrice": "20.15",
  "salePrice": "21.16"
  },
  {
  "bankName": "Monex",
  "buyPrice": "19.60",
  "salePrice": "21.66"
  },
  // {
  // "bankName": "Para pagos de obligaciones",
  // "buyPrice": "20.4872",
  // "salePrice": "20.4872"
  // },
  // {
  // "bankName": "SAT, Servicio de Administración Tributaria",
  // "buyPrice": "20.2993",
  // "salePrice": "20.2993"
  // },
  {
  "bankName": "Ve por mas",
  "buyPrice": "20.03",
  "salePrice": "21.26"
  },
  {
  "bankName": "Intercam",
  "buyPrice": "18.71",
  "salePrice": "20.21"
  }
  ];

const CalculatorEs = () => {
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [amount, setAmount] = useState('');
  const [convertedAmount, setConvertedAmount] = useState('');
  const [isMxnToUsd, setIsMxnToUsd] = useState(true); // Toggle state for currency direction
  const [exchangeRate , setExchangeRate] = useState(null)

const fromCurrency = 'USD'
const toCurrency = 'MXN'

  const fetchExchangeRate = async (from, to) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}?pair=${fromCurrency}${toCurrency}=X`);
      const data = await response.json();
      if (data && data.regularMarketPrice) {
        setExchangeRate(data.regularMarketPrice);
      }
    } catch (error) {
      console.error('Error fetching exchange rate:', error);
    }
  };

  useEffect(() => {
    fetchExchangeRate(fromCurrency, toCurrency); // Initial fetch

    // Set interval to update exchange rate every 10 minutes (600000ms)
    const intervalId = setInterval(() => {
      fetchExchangeRate(fromCurrency, toCurrency);
    }, 600000); // 600000 ms = 10 minutes
    return () => clearInterval(intervalId);
  }, [fromCurrency, toCurrency]);


  const tkambioBuyRate = (exchangeRate * 0.9965).toFixed(2);  // Your "Compra" rate
  const tkambioSellRate = (exchangeRate * 1.0035).toFixed(2); // Your "Venta" rate

  const handleBankChange = (event) => {
    // const bankName = event.target.value;
    const bank = banks.find(b => b.bankName === event.target.value);
    setSelectedBank(bank || {});
  };
  useEffect(() => {
    if (banks.length > 0) {
      setSelectedBank(banks[0]);
    }
  }, [banks]);
  const formatWithCommas = (value) => {
    if (!value) return '';
    // Remove any non-numeric characters except for the decimal point
    const numericValue = value.replace(/[^0-9.]/g, '');
    // Split the number into integer and decimal parts
    const [integerPart, decimalPart] = numericValue.split('.');
  
    // Format the integer part with commas
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    // Reattach the decimal part if it exists
    return decimalPart !== undefined ? `${formattedInteger}.${decimalPart}` : formattedInteger;
  };
  
  const handleAmountChange = (event) => {
    const value = event.target.value;
    setAmount(value);
  
    if (!isNaN(value) && value.trim() !== '') {
      const rate = isMxnToUsd ? mxntousd : usdtomxn;
      const converted = (value * rate).toFixed(2);
      setConvertedAmount(formatWithCommas(converted));
    } else {
      setConvertedAmount('');
    }
  };
  
  const calculateSavings = () => {
    if (!selectedBank) return { buySavings: 0, sellSavings: 0 };
  
    const buySavings = ((selectedBank.buyPrice || 0) - tkambioBuyRate) * amount;
    const sellSavings = (tkambioSellRate - (selectedBank.salePrice || 0)) * amount;
  
    return {
      buySavings: formatWithCommas(buySavings.toFixed(2)),
      sellSavings: formatWithCommas(sellSavings.toFixed(2)),
    };
  };


  const toggleCurrency = () => {
    setIsMxnToUsd(!isMxnToUsd);
    if (amount) {
      const rate = !isMxnToUsd ? mxntousd : usdtomxn; 
      setConvertedAmount((amount * rate).toFixed(2));
    }
  };

  useEffect(()=>{
    setBanks(exchange_rate_mxn)
  },[])


  const usdtomxn = tkambioSellRate;
  const mxntousd = tkambioBuyRate;

  const { buySavings, sellSavings } = calculateSavings();

  return (
    <div className="flex justify-center items-center md:mt-0 mt-9 md:w-[400px] md:shadow-none shadow-lg rounded-lg">
      <div className="bg-white rounded-lg p-5">
        <h3 className="text-xl font-semibold text-primary text-center">Compara nuestro tipo de cambio</h3>
        
        {/* Rates */}
        <div className="flex md:flex-row flex-col gap-2 md:justify-between md:items-stretch items-center  mt-3">
          <div>
            <img className="w-[8rem] mb-3" src={logo} alt="Logo" />
            <div className="flex items-center justify-center gap-1">
              <div className={`border rounded-lg p-2 w-[5.5rem] text-primary font-medium ${isMxnToUsd ? 'bg-primary/20' : ''}`}>
                <p className='mb-1'>Compra*</p>
                <p>${tkambioBuyRate}</p>
              </div>
              <div className={`border rounded-lg p-2 w-[5.5rem] text-primary font-medium ${isMxnToUsd ? '' : 'bg-primary/20'}`}>
                <p className='mb-1'>Venta*</p>
                <p>${tkambioSellRate}</p>
              </div>
            </div>
          </div>

          {/* Bank Rates */}
          <div>
            <div className="flex items-center justify-center">
              {/* <h3 className="text-2xl font-semibold">Banco:</h3> */}
              <select className="w-32 bg-primary text-white rounded-lg p-2 mb-3" onChange={handleBankChange} >
                <optgroup label="Banco">
                  {banks.map((bank, index) => (
                    <option key={index} value={bank.bankName}>
                      {bank.bankName}
                    </option>
                  ))}
                </optgroup>
              </select>
            </div>
            <div className="flex items-center justify-center gap-1">
              <div className={`border rounded-lg p-2 w-[5.5rem] text-gray-700 font-medium  ${isMxnToUsd ? 'bg-gray-300' : ''}`}>
                <p>Compra</p>
                <p>${selectedBank?.buyPrice || "N/A"}</p>
              </div>
              <div className={`border rounded-lg p-2 w-[5.5rem] text-gray-700 font-medium ${isMxnToUsd ? '' : 'bg-gray-300'}`}>
                <p>Venta</p>
                <p>${selectedBank?.salePrice || "N/A"}</p>
              </div>
            </div>
            <div className='text-end bg-primary/20 w-auto p-1 rounded-full text-sm mt-2 text-primary'>
              Percio Promedio Bancos
            </div>
          </div>
        </div>

        <div className='text-center text-primary font-semibold text-2xl mt-2'>Calculadora del ahorro</div>

        <div className='relative'>
          {/* Conversion */}
          <div className="flex items-center justify-between mt-3 w-full border p-2 rounded-lg">
            <div className="flex items-center justify-center gap-2">
              <div className="flex items-center gap-2 text-primary">
                <img className="w-[2rem] rounded-full h-[2rem]" src={isMxnToUsd ? mxn : usd} alt="Currency" />
                {isMxnToUsd ? 'MXN' : 'USD'}
              </div>
            </div>
            <input
              type="text"
              className="outline-none px-3 py-1 w-[43%] text-xl"
              placeholder="Transfieres"
              value={amount}
              onChange={handleAmountChange}
            />
          </div>

          <img
            className="w-[2.8rem] cursor-pointer mt-2 absolute top-[1.8rem] left-[8rem]"
            src={arrowchange}
            alt="Swap currencies"
            onClick={toggleCurrency}
          />

          {/* Recieve */}
          <div className="flex items-center justify-between mt-3 w-full border p-2 rounded-lg">
            <div className='flex items-center gap-2 text-primary'>
              <img className="w-[2rem] rounded-full h-[2rem]" src={isMxnToUsd ? usd : mxn} alt="Currency" />
              {isMxnToUsd ? 'USD' : 'MXN'}
            </div>
            <input
              type="text"
              className="outline-none px-3 py-1 w-[43%] text-xl"
              placeholder="Recibes"
              value={convertedAmount}
              disabled
            />
          </div>
        </div>

        {/* Savings */}
        <div className="p-2 bg-[#dfe5ff] border border-[#B0BFFF] rounded mt-3">
          <p className="text-sm text-primary font-semibold flex items-center justify-between mr-2">
            <span>Ahorrarás con <br /> TKambio USA: </span>
            <span className='text-xl'>{isMxnToUsd ? sellSavings : buySavings} MXN</span>
          </p>
        </div>

        <Link to={process.env.REACT_APP_REGISTER} className="flex justify-center text-white font-medium py-2 rounded-lg mt-3 bg-secondary text-center">
          Registrate
        </Link>
      </div>
    </div>
  );
};

export default CalculatorEs;
