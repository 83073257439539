import React, { useContext, useState } from "react";
import { motion } from "framer-motion";
import { IoIosArrowDown } from "react-icons/io";
import bg from '../../assets/faqbg.png'
import {LanguageContext} from '../../context/LanguageContext'

import translations from "../../languageContent/FAQContent";

const FAQs = () => {
    const {language} = useContext(LanguageContext)
    const [openIndex, setOpenIndex] = useState(null);

    const toggleFAQ = (index) => {
        setOpenIndex(index === openIndex ? null : index);
    };

    const t = translations[language]

    return (
        <div className="max-w-4xl mx-auto rounded-t-2xl" style={{ backgroundImage: `url(${bg})` }}>
            <div className="max-w-2xl mx-auto py-8 px-4 ">
                <h1 className="text-primary text-center text-3xl font-semibold">{language === 'en' ? "Did you have any questions left?": "¿Tienes alguna duda?"}</h1>
                <h2 className="text-xl font-medium text-black/50 mb-6 text-center">{language === 'en'?"Frequently Asked Questions":"Preguntas frecuentes"}</h2>
                <div className="space-y-4">
                    {t.map((faq, index) => (
                        <div
                            key={index}
                            className="border border-gray-200 rounded-lg overflow-hidden shadow-sm"
                        >
                            {/* Question */}
                            <button
                                onClick={() => toggleFAQ(index)}
                                className="w-full flex justify-between items-center p-4 bg-gray-50 text-left"
                            >
                                <span className="font-medium">{faq.question}</span>
                                <motion.div
                                    initial={{ rotate: 0 }}
                                    animate={{ rotate: openIndex === index ? 180 : 0 }}
                                    transition={{ duration: 0.3 }}
                                    className="text-gray-500"
                                >
                                    {/* ⬇️ */}
                                    <IoIosArrowDown />
                                </motion.div>
                            </button>

                            {/* Answer */}
                            <motion.div
                                initial={{ height: 0, opacity: 0 }}
                                animate={{
                                    height: openIndex === index ? "auto" : 0,
                                    opacity: openIndex === index ? 1 : 0,
                                }}
                                transition={{ duration: 0.5 }}
                                className="overflow-hidden bg-white"
                            >
                                <div className="p-4 text-gray-600">{faq.answer}</div>
                            </motion.div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FAQs;
