import blog2 from '../assets/DONALD-TRUMP.webp'
const blogs={
    en:[
        {
            id:1,
            title:"Finance of America Companies Inc. (FOA) Q3 2024 Earnings Call Transcript",
            date:"2022-01-01",
            category:"Finance",
            content:`<p>Thank you, and good afternoon, everyone and welcome to Finance of America's third quarter 2024 Earnings Call. With me today are Graham Fleming, Chief Executive Officer; Kristen Sieffert, President; and Matt Engel, Chief Financial Officer. </p>
            <p>In addition, we will refer to certain non-GAAP financial measures on this call. You can find reconciliations of non-GAAP to GAAP financial measures discussed on today's call in our earnings press release and presentation on the Investor Relations page of our website to the extent available without unreasonable efforts.</p>
            <p>Also, I would like to remind everyone that comments on this conference call may be forward-looking statements within the meaning of the Private Securities Litigation Reform Act of 1995. Regarding the company's expected operating and financial performance for future periods. These statements are based on the company's current expectations and are subject to the Safe Harbor statement for forward-looking statements that you will find in today's earnings release.</p>`,
            img:'https://cdn.snapi.dev/images/v1/t/r/transcript4-1827331-2746124.jpg',
        },
        {
            id:2,
            title:"Earnings Estimates Moving Higher for Finance of America Companies (FOA): Time to Buy?",
            date:"2022-01-01",
            category:"Finance",
            content:`<p>Finance of America Companies Inc. (FOA Quick QuoteFOA - Free Report) appears an attractive pick given a noticeable improvement in the company's earnings outlook. The stock has been a strong performer lately, and the momentum might continue with analysts still raising their earnings estimates for the company.</p>
            <p>The rising trend in estimate revisions, which is a result of growing analyst optimism on the earnings prospects of this company, should get reflected in its stock price. After all, empirical research shows a strong correlation between trends in earnings estimate revisions and near-term stock price movements. Our stock rating tool -- the Zacks Rank -- is principally built on this insight.</p>
            <p>The five-grade Zacks Rank system, which ranges from a Zacks Rank #1 (Strong Buy) to a Zacks Rank #5 (Strong Sell), has an impressive externally-audited track record of outperformance, with Zacks #1 Ranked stocks generating an average annual return of +25% since 2008.</p>
            <p>For Finance of America Companies, strong agreement among the covering analysts in revising earnings estimates upward has resulted in meaningful improvement in consensus estimates for the next quarter and full year.</p>
            <h5>Current-Quarter Estimate Revisions</h5>
            <p>The company is expected to earn $0.45 per share for the current quarter, which represents a year-over-year change of +150%. Over the last 30 days, one estimate has moved higher for Finance of America Companies compared to no negative revisions. As a result, the Zacks Consensus Estimate has increased 28.57%.</p>
            <h5>Current-Year Estimate Revisions</h5>
            <p>For the full year, the earnings estimate of $0.75 per share represents a change of +118.75% from the year-ago number.
There has been an encouraging trend in estimate revisions for the current year as well. Over the past month, one estimate has moved up for Finance of America Companies versus no negative revisions. This has pushed the consensus estimate 581.82% higher.</p>
<h5>Favorable Zacks Rank</h5>
<p>The promising estimate revisions have helped Finance of America Companies earn a Zacks Rank #1 (Strong Buy). The Zacks Rank is a tried-and-tested rating tool that helps investors effectively harness the power of earnings estimate revisions and make the right investment decision. You can see the complete list of today's Zacks #1 Rank (Strong Buy) stocks here.
Our research shows that stocks with Zacks Rank #1 (Strong Buy) and 2 (Buy) significantly outperform the S&P 500.</p>`,
            img:'https://cdn.snapi.dev/images/v1/x/i/v/bank30-2802070.jpg',
        },
        {
            id:3,
            title:"Fast-paced Momentum Stock Finance of America Companies (FOA) Is Still Trading at a Bargain",
            date:"2022-01-01",
            category:"Finance",
            content:`<p>Momentum investing is essentially an exception to the idea of "buying low and selling high." Investors following this style of investing are usually not interested in betting on cheap stocks and waiting long for them to recover. Instead, they believe that "buying high and selling higher" is the way to make far more money in lesser time.Who doesn't like betting on fast-moving trending stocks? But determining the right entry point isn't easy. </p>
            <p>Often, these stocks lose momentum once their valuation moves ahead of their future growth potential. In such a situation, investors find themselves loaded up on expensive shares with limited to no upside or even a downside. So, going all-in on momentum could be risky at times.A safer approach could be investing in bargain stocks with recent price momentum. While the Zacks Momentum Style Score (part of the Zacks Style Scores system) helps identify great momentum stocks by paying close attention to trends in a stock's price or earnings, our 'Fast-Paced Momentum at a Bargain' screen comes handy in spotting fast-moving stocks that are still attractively priced.</p>
            <p>Finance of America Companies Inc. (FOA Quick QuoteFOA - Free Report) is one of the several great candidates that made it through the screen. While there are numerous reasons why this stock is a great choice, here are the most vital ones:</p>
            <p>A dash of recent price momentum reflects growing interest of investors in a stock. With a four-week price change of 54.2%, the stock of this company is certainly well-positioned in this regard.</p>
            <p>While any stock can see a spike in price for a short period, it takes a real momentum player to deliver positive returns for a longer time frame. FOA meets this criterion too, as the stock gained 184.1% over the past 12 weeks.</p>
            <p>Moreover, the momentum for FOA is fast paced, as the stock currently has a beta of 1.22. This indicates that the stock moves 22% higher than the market in either direction.</p>
            <p>Given this price performance, it is no surprise that FOA has a Momentum Score of B, which indicates that this is the right time to enter the stock to take advantage of the momentum with the highest probability of success.</p>`,
            img:'https://staticx-tuner.zacks.com/images/default_article_images/default213.jpg',
        },
        {
            id:4,
            title:"Banco BPM says ECB, EBA both reviewing use of 'Danish Compromise' in Anima bid",
            date:"2022-01-01",
            category:"Finance",
            content:`<p>MILAN, Jan 20 (Reuters) - The European Central Bank has sought a regulatory clarification from the European Banking Authority over a request by Italy's Banco BPM to apply favourable rules known as "Danish Compromise" in its bid for fund manager Anima Holding, the bank said.
Banco BPM (BAMI.MI), opens new tab, which in the meantime has become a takeover target for rival UniCredit (CRDI.MI), opens new tab, in November launched a buyout offer for Anima (ANIM.MI), opens new tab, contingent on the ECB allowing it to apply the Danish Compromise rules.</p>
<p>Such rules allow a bank to risk-weigh an insurance holding instead of deducting it in full from its regulatory capital.
In BPM's case, the rule would also apply to an asset bought via the insurance arm. Banco BPM has bid for Anima through its insurance subsidiary BPM Vita.</p>
<p>The market is awaiting the ECB's response to BPM over Anima, as well as a similar response due to BNP Paribas (BNPP.PA), opens new tab over its purchase of AXA Investment Managers, because it could stoke similar deals, with banks buying asset managers through their insurance unit.The ECB has said it will take a case by case approach in deciding whether to grant the use of the Danish Compromise rule.</p>
<p>Banco BPM said discussions with the ECB over the issue were ongoing, adding the ECB had involved the EBA in its assessment.
The ECB declined to comment.
"We would expect Banco BPM-Anima to receive the supervisor's green light, alongside BNP-AXA IM and likely any future similar deals," Mediobanca Securities analysts said in a note.</p>`,
            img:'https://www.reuters.com/resizer/v2/466BJJQ7PVGY5O53NZ3KL65MHM.png?auth=b9c3bf166c40a6778eb5672993fde7c30a15f48329026674eff92afd8da1d0ca&width=960&quality=80',
        },
        {
            id:5,
            title:"Trump appoints Travis Hill as FDIC acting chair, White House says",
            date:"2022-01-01",
            category:"Finance",
            content:`<p>WASHINGTON, Jan 20 (Reuters) - Newly sworn-in President Donald Trump on Monday appointed Federal Deposit Insurance Corporation vice chair Travis Hill to lead the agency on an acting basis, the White House said.
Hill, who is also widely seen as the top contender for the job permanently, has worked in banking policy and regulation in Washington for over a decade. He has served as the FDIC's vice chair since 2023. He previously was a senior adviser to then-FDIC Chairman Jelena McWilliams, who led the agency during Trump's first term.</p>
<p>Hill takes over from FDIC Chairman Martin Gruenberg. A spokesperson for Hill declined to comment.
Prior to the FDIC, Hill served as counsel on the Senate Banking Committee, where he helped the panel advance legislation that eased post-crisis rules for regional banks, establishing a new "tailored" regulatory system for all but the nation's largest lenders.</p>
<p>At the FDIC, Hill has criticized efforts by Democratic regulators to impose strict new capital rules on bigger banks, commonly known as "Basel III endgame." He also challenged efforts to create a new framework requiring large asset managers to prove they were not influencing banks where they hold large stakes.</p>
<p>Hill will lead an agency undergoing a significant overhaul, following revelations of widespread sexual harassment and employee mistreatment at the watchdog.
Hill has called for a comprehensive overhaul of the FDIC's culture and policies, including identifying and holding employees who engaged in misconduct accountable.</p>`,
            img:'https://www.reuters.com/resizer/v2/G6KN3MNTOBJYNOTGNBNHA24OS4.jpg?auth=ee0d30861b1389c54dbf1179df8addc489c5dbeec2f28fa651cd7b79640bdff6&width=640&quality=80',
        },
        {
            id:6,
            title:"Banks running Trump 'war rooms' as bosses prepare for trade ructions",
            date:"January 21, 2025",
            category:"Finance",
            content:`<p>DAVOS, Switzerland, Jan 21 (Reuters) - JPMorgan Chase & Co (JPM.N), opens new tab bankers worked through the night in a "war room" to assess the impact of U.S. President Donald Trump's inauguration-day executive orders, while global markets braced for volatility following his return to the White House.</p>
            <p>Trump revoked nearly 80 executive actions by former Democratic President Joe Biden within hours of his second presidential oath of office, including orders for an immediate freeze on new federal regulations and government hiring."The last 24 hours are showing there's going to be a lot of changes we all have to digest," JPMorgan Chase & Co (JPM.N), opens new tab head of asset and wealth management Mary Callahan Erdoes told a panel discussion at the World Economic Forum in Davos, Switzerland.</p>
            <p>"At JPMorgan we have a war room set up to analyse and evaluate each and every one of these, so they have been up all night and are working on it."
The white-knuckle business of trading global assets sensitive to Trump's "America First" policies has resumed, brokers told Reuters, pointing to a rapid fall in the Canadian dollar against its U.S. counterpart, seconds after the president said a 25% tariff on Canadian goods could land within days.</p>
<p>Such changes and a possible increase in market volatility - sparked by Trump's unpredictable use of social media as observed in his first term as president - will require adjustments but, the bankers and traders said, rewards are there for those who can navigate this.
"Time will tell but a lot of this is exactly what you would do to have a very pro-business environment," Erdoes said, reflecting on Trump's early executive order to ban remote working for federal staff.</p>
<p>"Thank God the U.S. government has done it, and hopefully that'll keep us ahead of other governments in the world so we can continue to compete."
Global trade flows will suffer from "interesting ructions" as the new Trump administration settles in, Standard Chartered (STAN.L), opens new tab CEO Bill Winters told the Davos meeting.
"We'll see what comes through in terms of tariffs...but we know China is a big part of that in terms of having a gigantic export surplus, and that will be under attack from all parts of the world," Winters said.</p>
<p>Chinese officials are hopeful their country can avoid a repeat of the bruising trade wars that drove a wedge between the world's two economic superpowers during the last Trump administration in 2017-21, despite the returning president's robust comments on potential tariffs during his campaign.
Big, globally-focused banks will be able to benefit from that disruption in their roles connecting between markets, Winters said, while locally-focused banks may struggle.</p>`,
            img:'https://www.reuters.com/resizer/v2/YOO3Y7AUKRJK7CIRA5MXQ3XZBQ.jpg?auth=602c3d4723f0b4f50f70d6434ea675f250e9071307f3ff6ccf32b1ccf4e218a5&width=960&quality=80',
        },
        {
            id:7,
            title:"Bitcoin edges higher as investors shake off initial Trump Day One disappointment",
            date:"January 20, 2025",
            category:"Finance",
            content:`<p>A separate meme coin launched by first lady Melania Trump was down 44.4% at $4.28, with a market capitalization of about $823 million.
World Liberty Financial, a separate Trump-linked crypto project, also announced on Monday it had completed an initial token sale, raising $300 million, and that it would issue additional tokens. Trump has pledged to hand management of his assets to his children, but his crypto ventures have been criticized by ethics experts as raising conflict of interest issues and stoking speculation in a class of assets seen as volatile.</p>
<p>In a move viewed as positive by the crypto industry, Trump on Monday tapped Mark Uyeda, a Republican member of the U.S. Securities and Exchange Commission, as acting chair of the SEC.</p>
<p>He and fellow Republican commissioner Hester Peirce are expected to kick-start an overhaul of crypto policy at the agency, which cracked down on the industry under former President Joe Biden's Democratic SEC chair, Gary Gensler.</p>
<p>Trump is also still expected to issue executive orders in coming days that will further promote bitcoin adoption, Reuters and other media outlets have reported.
Speaking to Reuters Global Markets Forum at the start of this week's World Economic Forum's annual meeting in Davos, Jeremy Allaire, CEO of stablecoin issuer Circle, said he expected executive orders "imminently" which he said could allow banks to trade crypto, offer crypto investments to wealthy clients and hold it in portfolios.</p>`,
            img:'https://www.reuters.com/resizer/v2/RSO64AACB5MXPIUCMQ4YBGMKCA.jpg?auth=8a21ff893e687341338084cb1321722bad212d787be6a3674ed5e5bd7d425b8e&width=720&quality=80',
        },
        {
            id:8,
            title:"Trump’s SEC launching ‘crypto task force’ to develop clear regulations for industry",
            date:"Jan 21 2025",
            category:"Finance",
            content:`<p>The Trump administration took its first steps Tuesday toward an expected lowering of regulatory barriers for cryptocurrency.</p>
            <p>In a long-awaited move from the digital assets industry, the Securities and Exchange Commission announced that acting Chair Mark Uyeda has launched a “crypto task force” aimed at “developing a comprehensive and clear regulatory framework for crypto assets.”</p>
            <p>During the presidential campaign, Donald Trump promised a crypto-friendly administration for the $3.7 trillion industry.</p>
            <p>Though Uyeda announced the initiative, it will be led by SEC Commissioner Hester Peirce. Uyeda is holding the chair set until Paul Atkins gets through the confirmation process and providing enforcement resources.</p>
            <p>According to the SEC announcement, the panel’s task will be to develop a clear set of rules while also addressing issues regarding registration of coins.</p>
            <p>“We look forward to working hand-in-hand with the public to foster a regulatory environment that protects investors, facilitates capital formation, fosters market integrity, and supports innovation,” Peirce said.</p>
            <p>Bitcoin rallied on the announcement, up some 2.4% to more than $106,000.</p>
            <p>Just before his inauguration, Trump underlined his support for digital currencies by releasing his own meme coin, called $TRUMP, while first lady Melania Trump released a meme coin called $Melania. Both tokens saw quick surges in price before turning sharply lower.</p>
            <p>The White House’s view on crypto differs sharply from when Joe Biden was president. Then-SEC Chair Gary Gensler was viewed as an enemy of the industry.</p>
            <p>During Gensler’s time at the commission, he resisted efforts to establish crypto exchange-traded funds, endorsed tough accounting policies and filed enforcement cases against industry leaders.</p>
            <p>“Clarity regarding who must register, and practical solutions for those seeking to register, have been elusive. The result has been confusion about what is legal, which creates an environment hostile to innovation and conducive to fraud,” the commission’s announcement said. “The SEC can do better.”</p>
            <p>“This undertaking will take time, patience, and much hard work. It will succeed only if the Task Force has input from a wide range of investors, industry participants, academics, and other interested parties,” Peirce said.</p>
            `,
            img:'https://image.cnbcfm.com/api/v1/image/108089951-1737482155274-gettyimages-2171872900-GEORGETOWN_FINANCIAL_MARKETS.jpeg?v=1737482178&w=1480&h=833&ffmt=webp&vtcrop=y',
        },
        {
            id:9,
            title:"Trump to announce AI infrastructure investment backed by Oracle, OpenAI and Softbank",
            date:"Jan 21 2025",
            category:"Finance",
            content:`<p>President Donald Trump will announce a joint venture Tuesday with OpenAI, Oracle and Softbank to invest billions of dollars in AI infrastructure in the United States, CNBC’s Eamon Javers and NBC News’ Peter Alexander report.</p>
            <p>The project, dubbed Stargate, will be unveiled at the White House by Trump, Softbank CEO Masayoshi Son and executives from Oracle and OpenAI, according to sources familiar with the plan.</p>
            <p>The companies are expected to commit to an initial $100 billion and up to $500 billion to the project over the next four years, according to CBS News, which first reported details of the expected announcement.</p>
            <p>Stargate’s first joint venture will be a data center in Texas, CBS reported.</p>
            <p>Softbank’s Son had already announced a four-year, $100-billion AI investment in the United States in December, when he visited then-President-elect Trump’s Mar-a-Lago resort.</p>
            <p>It was not immediately clear if the anticipated investment being announced Tuesday would be in addition to Son’s prior pledge.</p>
            `,
            img:'https://image.cnbcfm.com/api/v1/image/108076417-1734366641204-gettyimages-2190251713-ah100182_cnya75nn.jpeg?v=1734366672&w=1480&h=833&ffmt=webp&vtcrop=y',
        },
        {
            id:10,
            title:"Trump effect on the dollar: Should we be worried?",
            date:"2022-01-01",
            category:"Finance",
            content:`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nisl nec ris`,
            img:blog2,
        },
    ],
    es:[
        {
            id:1,
            title:"Transcripción de la llamada de resultados del tercer trimestre de 2024 de Finance of America Companies Inc. (FOA)",
            date:"2022-01-01",
            category:"Finance",
            content:`<p>Gracias y buenas tardes a todos y bienvenidos a la convocatoria de resultados del tercer trimestre de 2024 de Finance of America. Hoy me acompañan Graham Fleming, director ejecutivo; Kristen Sieffert, presidenta; y Matt Engel, director financiero. </p>
            <p>Además, en esta convocatoria nos referiremos a determinadas medidas financieras no GAAP. Puede encontrar conciliaciones de medidas financieras no GAAP con GAAP analizadas en la convocatoria de hoy en nuestro comunicado de prensa de ganancias y presentación en la página de Relaciones con inversores de nuestro sitio web en la medida en que esté disponible y sin esfuerzos irrazonables.</p>
            <p>Además, me gustaría recordarles a todos que los comentarios en esta conferencia telefónica pueden ser declaraciones prospectivas dentro del significado de la Ley de Reforma de Litigios sobre Valores Privados de 1995. Con respecto al desempeño operativo y financiero esperado de la compañía para períodos futuros. Estas declaraciones se basan en las expectativas actuales de la empresa y están sujetas a la declaración de puerto seguro para declaraciones prospectivas que encontrará en la publicación de resultados de hoy.</p>`,
            img:'https://cdn.snapi.dev/images/v1/t/r/transcript4-1827331-2746124.jpg',
        },
        {
            id:2,
            title:"Las estimaciones de ganancias aumentan para las empresas Finance of America (FOA): ¿es hora de comprar?",
            date:"2022-01-01",
            category:"Finance",
            content:`<p>Finance of America Companies Inc. (FOA Quick QuoteFOA - Informe gratuito) parece una elección atractiva dada una mejora notable en las perspectivas de ganancias de la compañía. La acción ha tenido un desempeño sólido últimamente y el impulso podría continuar si los analistas siguen elevando sus estimaciones de ganancias para la compañía.</p>
            <p>La tendencia creciente en las revisiones de estimaciones, que es resultado del creciente optimismo de los analistas sobre las perspectivas de ganancias de esta empresa, debería reflejarse en el precio de sus acciones. Después de todo, la investigación empírica muestra una fuerte correlación entre las tendencias en las revisiones de las estimaciones de ganancias y los movimientos del precio de las acciones a corto plazo. Nuestra herramienta de calificación de acciones, Zacks Rank, se basa principalmente en esta información.</p>
            <p>El sistema Zacks Rank de cinco grados, que va desde Zacks Rank #1 (compra fuerte) hasta Zacks Rank #5 (fuerte venta), tiene un impresionante historial de rendimiento superior auditado externamente, con Zacks #1 clasificado acciones que generan una rentabilidad anual media del +25 % desde 2008.</p>
            <p>Para Finance of America Companies, el fuerte acuerdo entre los analistas que cubren la revisión al alza de las estimaciones de ganancias ha resultado en una mejora significativa en las estimaciones de consenso para el próximo trimestre y el año completo.</p>
            <h5>Revisiones de estimaciones del trimestre actual</h5>
            <p>Se espera que la empresa gane 0,45 dólares por acción durante el trimestre actual, lo que representa un cambio interanual de +150%. Durante los últimos 30 días, una estimación ha subido para las empresas de Finance of America en comparación con ninguna revisión negativa. Como resultado, la estimación de consenso de Zacks ha aumentado un 28,57 %.</p>
            <h5>Revisiones de estimaciones del año actual</h5>
            <p>Para todo el año, la estimación de ganancias de 0,75 dólares por acción representa un cambio de +118,75 % con respecto a la cifra del año anterior.
También ha habido una tendencia alentadora en las revisiones de las estimaciones para el año en curso. Durante el mes pasado, una estimación subió para Finance of America Companies frente a ninguna revisión negativa. Esto ha elevado la estimación de consenso un 581,82 % más.</p>
<h5>Rango de Zacks favorable</h5>
<p>Las prometedoras revisiones de las estimaciones han ayudado a las empresas de Finance of America a obtener el puesto número 1 en Zacks (compra fuerte). Zacks Rank es una herramienta de calificación probada que ayuda a los inversores a aprovechar eficazmente el poder de las revisiones de las estimaciones de ganancias y a tomar la decisión de inversión correcta. Puede ver la lista completa de las acciones de Zacks #1 Rank (compra fuerte) de hoy aquí.
Nuestra investigación muestra que las acciones con el rango 1 (compra fuerte) y 2 (compra) de Zacks superan significativamente al S&P 500.</p>`,
            img:'https://cdn.snapi.dev/images/v1/x/i/v/bank30-2802070.jpg',
        },
        {
            id:3,
            title:"El rápido ritmo de Momentum Stock Finance of America Companies (FOA) sigue cotizando a precio de ganga",
            date:"2022-01-01",
            category:"Finance",
            content:`<p>La inversión de impulso es esencialmente una excepción a la idea de "comprar barato y vender caro". Los inversores que siguen este estilo de inversión no suelen estar interesados ​​en apostar por acciones baratas y esperar mucho a que se recuperen. En lugar de ello, creen que "comprar caro y vender más caro" es la forma de ganar mucho más dinero en menos tiempo. ¿A quién no le gusta apostar en acciones con tendencias de rápido movimiento? Pero determinar el punto de entrada correcto no es fácil. </p>
            <p>A menudo, estas acciones pierden impulso una vez que su valoración se adelanta a su potencial de crecimiento futuro. En tal situación, los inversores se encuentran cargados de acciones caras con ventajas limitadas o incluso negativas. Por lo tanto, apostar todo por el impulso podría ser arriesgado en ocasiones. Un enfoque más seguro podría ser invertir en acciones de ganga con un impulso de precios reciente. Si bien Zacks Momentum Style Score (parte del sistema Zacks Style Scores) ayuda a identificar acciones con gran impulso prestando mucha atención a las tendencias en el precio o las ganancias de una acción, nuestra pantalla 'Fast-Paced Momentum at a Bargain' resulta útil para detectar cambios rápidos. mover acciones que todavía tienen precios atractivos.</p>
            <p>Finance of America Companies Inc. (FOA Quick QuoteFOA - Informe gratuito) es uno de los varios grandes candidatos que logró pasar por la pantalla. Si bien existen numerosas razones por las que esta acción es una excelente opción, estas son las más importantes:</p>
            <p>Una pizca de impulso reciente de los precios refleja el creciente interés de los inversores en una acción. Con una variación de precio del 54,2% en cuatro semanas, las acciones de esta empresa están ciertamente bien posicionadas en este sentido.</p>
            <p>Si bien cualquier acción puede experimentar un aumento en el precio durante un período corto, se necesita un actor con impulso real para generar retornos positivos durante un período de tiempo más largo. FOA también cumple con este criterio, ya que la acción ganó un 184,1% en las últimas 12 semanas.</p>
            <p>Además, el impulso de FOA es rápido, ya que la acción tiene actualmente una beta de 1,22. Esto indica que la acción se mueve un 22% más que el mercado en cualquier dirección.</p>
            <p>Dado el desempeño de este precio, no sorprende que FOA tenga una puntuación de impulso de B, lo que indica que este es el momento adecuado para ingresar a la acción y aprovechar el impulso con la mayor probabilidad de éxito.</p>`,
            img:'https://staticx-tuner.zacks.com/images/default_article_images/default213.jpg',
        },
        {
            id:4,
            title:"Banco BPM dice que el BCE y la EBA están revisando el uso del compromiso danés en la oferta de Anima",
            date:"2022-01-01",
            category:"Finance",
            content:`<p>MILÁN, 20 ene (Reuters) - El Banco Central Europeo ha pedido una aclaración regulatoria a la Autoridad Bancaria Europea sobre una solicitud del Banco BPM de Italia para aplicar reglas favorables conocidas como "Compromiso Danés" en su oferta por la gestora de fondos Anima Holding. , dijo el banco.
Banco BPM (BAMI.MI), abre nueva pestaña, que mientras tanto se ha convertido en objetivo de adquisición de su rival UniCredit (CRDI.MI), abre nueva pestaña, en noviembre lanzó una oferta de compra sobre Anima (ANIM.MI), abre nueva pestaña, supeditado a que el BCE le permita aplicar las normas del Compromiso Danés.</p>
<p>Estas reglas permiten a un banco sopesar el riesgo de una participación de seguro en lugar de deducirlo en su totalidad de su capital regulatorio.
En el caso de BPM, la regla también se aplicaría a un activo comprado a través de la rama de seguros. Banco BPM ha ofertado por Anima a través de su filial aseguradora BPM Vita.</p>
<p>El mercado está a la espera de la respuesta del BCE a BPM sobre Anima, así como una respuesta similar debido a BNP Paribas (BNPP.PA), abre nueva pestaña sobre su compra de AXA Investment Managers, porque podría impulsar acuerdos similares, con bancos que compran administradores de activos a través de su unidad de seguros. El BCE ha dicho que adoptará un enfoque caso por caso para decidir si concede el uso de la norma de Compromiso Danesa.</p>
<p>Banco BPM dijo que las discusiones con el BCE sobre el tema estaban en curso y agregó que el BCE había involucrado a la EBA en su evaluación.
El BCE declinó hacer comentarios.
"Esperamos que Banco BPM-Anima reciba luz verde del supervisor, junto con BNP-AXA IM y probablemente cualquier acuerdo similar en el futuro", dijeron los analistas de Mediobanca Securities en una nota.</p>`,
            img:'https://www.reuters.com/resizer/v2/466BJJQ7PVGY5O53NZ3KL65MHM.png?auth=b9c3bf166c40a6778eb5672993fde7c30a15f48329026674eff92afd8da1d0ca&width=960&quality=80',
        },
        {
            id:5,
            title:"Trump nombra a Travis Hill presidente interino de la FDIC, dice la Casa Blanca",
            date:"2022-01-01",
            category:"Finance",
            content:`<p>WASHINGTON, 20 ene (Reuters) - El recién juramentado presidente Donald Trump nombró el lunes al vicepresidente de la Corporación Federal de Seguros de Depósitos, Travis Hill, para dirigir la agencia de manera interina, dijo la Casa Blanca.
Hill, quien también es ampliamente visto como el principal candidato para el puesto de forma permanente, ha trabajado en política y regulación bancaria en Washington durante más de una década. Se ha desempeñado como vicepresidente de la FDIC desde 2023. Anteriormente fue asesor principal de la entonces presidenta de la FDIC, Jelena McWilliams, quien dirigió la agencia durante el primer mandato de Trump.</p>
<p>Hill reemplaza al presidente de la FDIC, Martin Gruenberg. Un portavoz de Hill declinó hacer comentarios.
Antes de la FDIC, Hill se desempeñó como asesor del Comité Bancario del Senado, donde ayudó al panel a promover legislación que flexibilizó las reglas posteriores a la crisis para los bancos regionales, estableciendo un nuevo sistema regulatorio "adaptado" para todos, excepto los prestamistas más grandes del país. p>
<p>En la FDIC, Hill ha criticado los esfuerzos de los reguladores demócratas para imponer nuevas y estrictas reglas de capital a los bancos más grandes, lo que comúnmente se conoce como el "final de Basilea III". También cuestionó los esfuerzos por crear un nuevo marco que exija que los grandes administradores de activos demuestren que no están influyendo en los bancos donde tienen grandes participaciones.</p>
<p>Hill dirigirá una agencia que está experimentando una importante renovación, luego de revelaciones de acoso sexual generalizado y maltrato a los empleados en el organismo de control.
Hill ha pedido una revisión integral de la cultura y las políticas de la FDIC, incluida la identificación y responsabilización de los empleados que participaron en malas conductas.</p>`,
            img:'https://www.reuters.com/resizer/v2/G6KN3MNTOBJYNOTGNBNHA24OS4.jpg?auth=ee0d30861b1389c54dbf1179df8addc489c5dbeec2f28fa651cd7b79640bdff6&width=640&quality=80',
        },
        {
            id:6,
            title:"Los bancos administran las 'salas de guerra' de Trump mientras los patrones se preparan para las disputas comerciales",
            date:"January 21, 2025",
            category:"Finance",
            content:`<p>DAVOS, Suiza, 21 de enero (Reuters) - JPMorgan Chase & Co (JPM.N), abre una nueva cuenta Los banqueros trabajaron toda la noche en una "sala de guerra" para evaluar el impacto del ejecutivo del día de la toma de posesión del presidente de Estados Unidos, Donald Trump. pedidos, mientras los mercados globales se preparaban para la volatilidad tras su regreso a la Casa Blanca.</p>
            <p>Trump revocó casi 80 acciones ejecutivas del ex presidente demócrata Joe Biden pocas horas después de su segundo juramento presidencial, incluidas órdenes para congelar inmediatamente las nuevas regulaciones federales y la contratación gubernamental. "Las últimas 24 horas están demostrando que va a haber "Todos tenemos que digerir muchos cambios", dijo Mary Callahan Erdoes, jefa de gestión de activos y patrimonio de JPMorgan Chase & Co (JPM.N), abre una nueva pestaña, en una mesa redonda en el Foro Económico Mundial en Davos, Suiza.</p >
            <p>"En JPMorgan tenemos una sala de guerra preparada para analizar y evaluar todos y cada uno de estos, por lo que han estado despiertos toda la noche y están trabajando en ello".
El complicado negocio de negociar activos globales sensibles a las políticas de "Estados Unidos primero" de Trump se ha reanudado, dijeron corredores a Reuters, señalando una rápida caída del dólar canadiense frente a su contraparte estadounidense, segundos después de que el presidente anunciara un arancel del 25% sobre los productos canadienses. podría aterrizar en unos días.</p>
<p>Estos cambios y un posible aumento de la volatilidad del mercado, provocado por el uso impredecible de las redes sociales por parte de Trump, como se observó en su primer mandato como presidente, requerirán ajustes pero, dijeron los banqueros y comerciantes, hay recompensas para aquellos que puedan navegar por esto. .
"El tiempo lo dirá, pero mucho de esto es exactamente lo que se haría para tener un entorno muy favorable a las empresas", dijo Erdoes, reflexionando sobre la orden ejecutiva inicial de Trump para prohibir el trabajo remoto para el personal federal.</p>
<p>"Gracias a Dios, el gobierno de Estados Unidos lo ha hecho y, con suerte, eso nos mantendrá por delante de otros gobiernos del mundo para que podamos seguir compitiendo".
Los flujos comerciales globales sufrirán "alborotos interesantes" a medida que se asiente la nueva administración Trump, dijo Standard Chartered (STAN.L), abre una nueva pestaña, dijo el director ejecutivo Bill Winters en la reunión de Davos.
"Veremos qué sucede en términos de aranceles... pero sabemos que China es una gran parte de eso en términos de tener un superávit de exportación gigantesco, y eso será atacado desde todas partes del mundo", dijo Winters. .</p>
<p>Los funcionarios chinos tienen la esperanza de que su país pueda evitar una repetición de las duras guerras comerciales que abrieron una brecha entre las dos superpotencias económicas del mundo durante la última administración Trump en 2017-21, a pesar de los fuertes comentarios del presidente que regresa sobre posibles aranceles durante su campaña. .
Los grandes bancos con enfoque global podrán beneficiarse de esa interrupción en sus funciones de conexión entre mercados, dijo Winters, mientras que los bancos con enfoque local pueden tener dificultades.</p>`,
            img:'https://www.reuters.com/resizer/v2/YOO3Y7AUKRJK7CIRA5MXQ3XZBQ.jpg?auth=602c3d4723f0b4f50f70d6434ea675f250e9071307f3ff6ccf32b1ccf4e218a5&width=960&quality=80',
        },
        {
            id:7,
            title:"Bitcoin sube mientras los inversores se sacuden la decepción inicial del primer día de Trump",
            date:"January 20, 2025",
            category:"Finance",
            content:`<p>Una moneda meme separada lanzada por la primera dama Melania Trump bajó un 44,4% a 4,28 dólares, con una capitalización de mercado de alrededor de 823 millones de dólares.
World Liberty Financial, un proyecto criptográfico independiente vinculado a Trump, también anunció el lunes que había completado una venta inicial de tokens, recaudando 300 millones de dólares, y que emitiría tokens adicionales. Trump se ha comprometido a entregar la gestión de sus activos a sus hijos, pero sus empresas criptográficas han sido criticadas por expertos en ética por plantear problemas de conflictos de intereses y avivar la especulación en una clase de activos considerados volátiles.</p>
<p>En una medida considerada positiva por la industria de la criptografía, Trump nombró el lunes a Mark Uyeda, un miembro republicano de la Comisión de Bolsa y Valores de EE. UU., como presidente interino de la SEC.</p>
<p>Se espera que él y su colega comisionada republicana, Hester Peirce, pongan en marcha una revisión de la política de criptomonedas en la agencia, que tomó medidas enérgicas contra la industria bajo el presidente demócrata de la SEC del ex presidente Joe Biden, Gary Gensler.</p>
<p>También se espera que Trump emita órdenes ejecutivas en los próximos días que promoverán aún más la adopción de bitcoin, informaron Reuters y otros medios de comunicación.
En declaraciones al Foro de Mercados Globales de Reuters al comienzo de la reunión anual del Foro Económico Mundial de esta semana en Davos, Jeremy Allaire, director ejecutivo del emisor de monedas estables Circle, dijo que esperaba órdenes ejecutivas "inminentemente" que, según dijo, podrían permitir a los bancos comerciar con criptomonedas y ofrecer inversiones en criptomonedas. a clientes ricos y mantenerlos en carteras.</p>`,
            img:'https://www.reuters.com/resizer/v2/RSO64AACB5MXPIUCMQ4YBGMKCA.jpg?auth=8a21ff893e687341338084cb1321722bad212d787be6a3674ed5e5bd7d425b8e&width=720&quality=80',
        },
        {
            id:8,
            title:"La SEC de Trump lanza un grupo de trabajo sobre criptomonedas para desarrollar regulaciones claras para la industria",
            date:"Jan 21 2025",
            category:"Finance",
            content:`<p>La administración Trump dio sus primeros pasos el martes hacia la esperada reducción de las barreras regulatorias para las criptomonedas.</p>
            <p>En una medida muy esperada por parte de la industria de activos digitales, la Comisión de Bolsa y Valores anunció que el presidente en funciones, Mark Uyeda, ha lanzado un "grupo de trabajo sobre criptografía" destinado a "desarrollar un marco regulatorio completo y claro para los criptoactivos".< /p>
            <p>Durante la campaña presidencial, Donald Trump prometió una administración amigable con las criptomonedas para la industria de 3,7 billones de dólares.</p>
            <p>Aunque Uyeda anunció la iniciativa, estará dirigida por la comisionada de la SEC, Hester Peirce. Uyeda ocupará la presidencia hasta que Paul Atkins termine el proceso de confirmación y proporcione recursos para hacer cumplir la ley.</p>
            <p>Según el anuncio de la SEC, la tarea del panel será desarrollar un conjunto claro de reglas y al mismo tiempo abordar cuestiones relacionadas con el registro de monedas.</p>
            <p>“Esperamos trabajar mano a mano con el público para fomentar un entorno regulatorio que proteja a los inversores, facilite la formación de capital, fomente la integridad del mercado y apoye la innovación”, dijo Peirce.</p>
            <p>Bitcoin se recuperó tras el anuncio, con un aumento de alrededor del 2,4% a más de 106.000 dólares.</p>
            <p>Justo antes de su toma de posesión, Trump subrayó su apoyo a las monedas digitales al lanzar su propia moneda meme, llamada $TRUMP, mientras que la primera dama Melania Trump lanzó una moneda meme llamada $Melania. Ambos tokens experimentaron rápidos aumentos en el precio antes de caer bruscamente.</p>
            <p>La opinión de la Casa Blanca sobre las criptomonedas difiere marcadamente de cuando Joe Biden era presidente. El entonces presidente de la SEC, Gary Gensler, era visto como un enemigo de la industria.</p>
            <p>Durante el tiempo que Gensler estuvo en la comisión, se resistió a los esfuerzos por establecer fondos cotizados en bolsa de criptomonedas, respaldó políticas contables estrictas y presentó casos de ejecución contra líderes de la industria.</p>
            <p>“Ha sido difícil lograr claridad sobre quién debe registrarse y soluciones prácticas para quienes buscan registrarse. El resultado ha sido una confusión sobre lo que es legal, lo que crea un entorno hostil a la innovación y propicio al fraude”, decía el anuncio de la comisión. "La SEC puede hacerlo mejor".</p>
            <p>“Esta tarea requerirá tiempo, paciencia y mucho trabajo duro. Sólo tendrá éxito si el Grupo de Trabajo cuenta con aportes de una amplia gama de inversores, participantes de la industria, académicos y otras partes interesadas”, afirmó Peirce.</p>
            `,
            img:'https://image.cnbcfm.com/api/v1/image/108089951-1737482155274-gettyimages-2171872900-GEORGETOWN_FINANCIAL_MARKETS.jpeg?v=1737482178&w=1480&h=833&ffmt=webp&vtcrop=y',
        },
        {
            id:9,
            title:"Trump anunciará una inversión en infraestructura de IA respaldada por Oracle, OpenAI y Softbank",
            date:"Jan 21 2025",
            category:"Finance",
            content:`<p>El presidente Donald Trump anunciará el martes una empresa conjunta con OpenAI, Oracle y Softbank para invertir miles de millones de dólares en infraestructura de IA en los Estados Unidos, informan Eamon Javers de CNBC y Peter Alexander de NBC News.</p>
            <p>El proyecto, denominado Stargate, será presentado en la Casa Blanca por Trump, el director ejecutivo de Softbank, Masayoshi Son, y ejecutivos de Oracle y OpenAI, según fuentes familiarizadas con el plan.</p>
            <p>Se espera que las empresas se comprometan con una inversión inicial de 100 mil millones de dólares y hasta 500 mil millones de dólares para el proyecto durante los próximos cuatro años, según CBS News, que fue el primero en informar los detalles del esperado anuncio.</p>
            <p>La primera empresa conjunta de Stargate será un centro de datos en Texas, informó CBS.</p>
            <p>El hijo de Softbank ya había anunciado una inversión de cuatro años y 100.000 millones de dólares en IA en Estados Unidos en diciembre, cuando visitó el complejo Mar-a-Lago del entonces presidente electo Trump.</p>
            <p>No quedó claro de inmediato si la inversión prevista que se anunció el martes se sumaría a la promesa anterior de Son.</p>
            `,
            img:'https://image.cnbcfm.com/api/v1/image/108076417-1734366641204-gettyimages-2190251713-ah100182_cnya75nn.jpeg?v=1734366672&w=1480&h=833&ffmt=webp&vtcrop=y',
        },
        {
            id:10,
            title:"Efecto Trump sobre el dólar: ¿deberíamos preocuparnos?",
            date:"2022-01-01",
            category:"Finance",
            content:`<p>La administración Trump dio sus primeros pasos el martes hacia la esperada reducción de las barreras regulatorias para las criptomonedas.</p>
            <p>En una medida muy esperada por parte de la industria de activos digitales, la Comisión de Bolsa y Valores anunció que el presidente en funciones, Mark Uyeda, ha lanzado un "grupo de trabajo sobre criptografía" destinado a "desarrollar un marco regulatorio completo y claro para los criptoactivos".< /p>
            <p>Durante la campaña presidencial, Donald Trump prometió una administración amigable con las criptomonedas para la industria de 3,7 billones de dólares.</p>
            <p>Aunque Uyeda anunció la iniciativa, estará dirigida por la comisionada de la SEC, Hester Peirce. Uyeda ocupará la presidencia hasta que Paul Atkins termine el proceso de confirmación y proporcione recursos para hacer cumplir la ley.</p>
            <p>Según el anuncio de la SEC, la tarea del panel será desarrollar un conjunto claro de reglas y al mismo tiempo abordar cuestiones relacionadas con el registro de monedas.</p>
            <p>“Esperamos trabajar mano a mano con el público para fomentar un entorno regulatorio que proteja a los inversores, facilite la formación de capital, fomente la integridad del mercado y apoye la innovación”, dijo Peirce.</p>`,
            img:blog2,
        },
    ],
}

export default blogs;