import React from 'react'
import { Link } from 'react-router-dom';

const WhyChoose = () => {
    const data = ["We have the robust infrastructure of Monex USA, which has more than 38 years of experience in the foreign exchange market.","We have unparalleled purchasing power because we are one of the largest foreign exchange providers in the world.","Our platform is easy to use and offers a competitive exchange rate at all times"];
  return (
    <div className='mt-16 max-w-[1300px] mx-auto flex flex-col items-center'>
      <h1 className="text-center text-4xl font-semibold text-primary mb-1">Why choose TKambio USA?</h1>
      <p className='font-medium text-center text-primary'>No SWIFT, Wires and SPEI charges</p>
      <div className="flex md:flex-row flex-col mx-6 gap-8 mt-6 mb-9">
        {data.map((i,index)=>{
            return(
                <div className='bg-[#EBEEF6] p-4 w-[100%] text-[#4a4a4a]' key={index}>{i}</div>
            )
        })}
      </div>
        <Link className='py-2 px-4 bg-secondary text-white rounded-lg hover:-translate-y-1 duration-200 shadow-lg hover:shadow-black/50' to={process.env.REACT_APP_SCHEDULE_DEMO}>Schedule Demo</Link>
    </div>
  )
}

export default WhyChoose
