import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import blogs from '../languageContent/blogs';
import { LanguageContext } from '../context/LanguageContext';
import '../blog.css'

const BlogView = () => {
    const { id } = useParams();
    const { language } = useContext(LanguageContext);

    // Find the blog in the correct language array
    const blog = (blogs[language] || []).find((blog) => blog.id === parseInt(id));

    if (!blog) {
        return <h2 className="text-center text-red-500">Blog not found</h2>;
    }

    return (
        <div className="max-w-[1300px] mx-auto p-6">
            <div className="blog">
                <h1 className="text-4xl font-bold mb-4">{blog.title}</h1>
                <div className="h-[450px] rounded-lg w-full overflow-hidden">
                    <img
                        className='w-full h-full object-cover'
                        src={blog.img}
                        alt=""
                    />
                </div>

                <div className='content' dangerouslySetInnerHTML={{ __html: blog.content }} />
            </div>
        </div>
    );
};

export default BlogView;
