import React from 'react'
import MainHero from '../MainHero'
import heroimg from '../../assets/forward-contract-hero.png';
const Hero = () => {
  return (
    <div>
      <MainHero
      enheading="Secure Your Future with Forward Contracts"
      esheading="Asegure su futuro con contratos a plazo"
      endesc="Protect your business from currency fluctuations and manage your cash flow effectively with Tkambio's tailored solutions."
      esdesc="Proteja su negocio de las fluctuaciones monetarias y administre su flujo de caja de manera efectiva con las soluciones personalizadas de Tkambio."
      enbtn="Secure the Future"
      esbtn="Asegurar el futuro"
      img={heroimg}
      />
    </div>
  )
}

export default Hero
