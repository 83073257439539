import React, { useContext } from "react";
import Card from "../Card";
import { LanguageContext } from "../../context/LanguageContext";

import card1 from "../../assets/card1.png";
import card2 from "../../assets/risk-img.png";
import card3 from "../../assets/percent.png";


const CardsSection = () => {
  const { language } = useContext(LanguageContext);

  const translations = {
    en: {
      card1heading: "Lock in Your Rates, Secure Your Future",
      card1desc:
        "Lock in future exchange rates with Tkambio’s forward contracts. This feature shields your business from currency fluctuations, providing certainty for your upcoming transactions.",
      card1btn: "Lock Rate",

      card2heading: "Strategic Risk Management",
      card2desc:
        "Manage currency risk by hedging against market volatility. Forward contracts help stabilize costs and protect profit margins for better financial planning.",
      card2btn: "Contact Us",

      card3heading: "No Upfront Payments Required",
      card3desc:
        "Enter into forward contracts without the burden of upfront payments. This makes it easy for businesses of all sizes to take advantage of favorable rates without immediate financial commitment.",
      card3btn: "Try it Out",

    },
    es: {
      card1heading: "Fije sus tarifas, asegure su futuro",
      card1desc:
        "Asegure los tipos de cambio futuros con los contratos a plazo de Tkambio. Esta característica protege su negocio de las fluctuaciones monetarias, brindando certeza para sus próximas transacciones.",
      card1btn: "Tasa de bloqueo",

      card2heading: "Gestión de riesgos estratégicos",
      card2desc:
        "Administre el riesgo cambiario cubriéndose contra la volatilidad del mercado. Los contratos a plazo ayudan a estabilizar los costos y proteger los márgenes de ganancias para una mejor planificación financiera.",
      card2btn: "Contáctenos",

      card3heading: "No se requieren pagos por adelantado",
      card3desc:
        "Celebre contratos a plazo sin la carga de los pagos iniciales. Esto facilita que las empresas de todos los tamaños aprovechen tasas favorables sin un compromiso financiero inmediato.",
      card3btn: "Pruébalo",

    },
  };

  const t = translations[language];
  return (
    <div className="bg-[#F2F4FF66] py-4">
      <Card
        title={t.card1heading}
        p={t.card1desc}
        btn={t.card1btn}
        img={card1}
        styles={"order-first"}
      />

      <div className="max-w-[1200px] mx-auto flex flex-col items-center">
        <h1 className="text-3xl font-semibold text-primary">{t.card2heading}</h1>
        <p className="text-center my-3 font-medium text-lg">{t.card2desc}</p>
        <button className="bg-secondary py-3 px-9 rounded-full text-white flex items-center my-6">{t.card2btn}</button>
        <img src={card2} alt="" />
      </div>

      <Card
        title={t.card3heading}
        p={t.card3desc}
        btn={t.card3btn}
        img={card3}
      />

      
    </div>
  );
};

export default CardsSection;
