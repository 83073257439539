import React, { useEffect } from 'react'
import Hero from '../components/ForeignExchange/Hero'
import Slider from '../components/ForeignExchange/Slider'
import CardSection from '../components/ForeignExchange/CardSection'
import Banner from '../components/ForeignExchange/Banner'
import Save from '../components/ForeignExchange/Save'
// import Questions from '../components/Questions'
import CalltoAction from '../components/CalltoAction'
import AOS from 'aos';  // Import AOS
import 'aos/dist/aos.css';

const ForeignExchange = () => {
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 500, 
      once:true,
      mirror: true, 
    });
  }, []);
  const sections = [<Hero/>,<Slider/>,<CardSection/>, <Banner/>,<Save/>,<CalltoAction/>]
  return (
    <div className=''>
      {sections.map((sec,index)=>{
        return(
          <div data-aos="fade-up" data-aos-delay={`${index * 100}`}>{sec}</div>
        )
      })}
    </div>
  )
}

export default ForeignExchange
