import React, { useEffect } from 'react'
import Hero from '../components/About/Hero'
import BirthofTkambio from '../components/About/BirthofTkambio'
import Banner from '../components/About/Banner'
import SecureTransaction from '../components/About/SecureTransaction'
import CalltoAction from '../components/CalltoAction'
import FAQs from '../components/Home/FAQs'
import AOS from 'aos';  // Import AOS
import 'aos/dist/aos.css';

const About = () => {
    useEffect(() => {
        // Initialize AOS
        AOS.init({
          duration: 500, 
          once:true,
          mirror: true, 
        });
      }, []);

      const sections = [<Hero/>,<BirthofTkambio/>,<Banner/>,<SecureTransaction/>,<CalltoAction/>,      <FAQs/>]
  return (
        <div className=''>
      {sections.map((sec,index)=>{
        return(
          <div data-aos="fade-up" data-aos-delay={`${index * 100}`}>{sec}</div>
        )
      })}
    </div>
  )
}

export default About
