import React, { useContext } from 'react'
import img from '../../assets/securet.png'
import { LanguageContext } from '../../context/LanguageContext';

const SecureTransaction = () => {
    const {language} = useContext(LanguageContext);
  return (
    <div className='md:my-[144px] my-[64px] max-w-[1400px] md:mx-auto mx-4'>
      <div className="flex md:flex-row flex-col md:gap-0 gap-5 items-center">
        <div className='flex-1'>
            <h1 className='text-[#141B45] md:text-[48px] text-[28px] font-semibold'>Our Commitment to Secure Transactions</h1>
            {language==='en'?(
                <p className='mt-4 md:text-[28px] text-[16px] text-[#141B45B2]'>Partnering with <span className='text-[#141B45]'>Monex USA</span>, at Tkambio we ensures secure, licensed transactions nationwide. With <span className='text-[#141B45]'>MSB registration and insurance from Liberty Mutual and The Hartford, plus added AML protections</span> from our compliance team, we keep your funds safe and your business protected.</p>
            ):(
                <p className='mt-4 md:text-[28px] text-[16px] text-[#141B45B2]'>Asociarse con <span className='text-[#141B45]'>Monex USA</span>, En Tkambio garantizamos transacciones seguras y con licencia en todo el país. Con <span className='text-[#141B45]'>Registro y seguro de MSB de Liberty Mutual y The Hartford, además de protecciones ALD adicionales</span> Desde nuestro equipo de cumplimiento, mantenemos sus fondos seguros y su negocio protegido.</p>
            )}
        </div>
        <div>
            <img src={img} alt="" />
        </div>
      </div>
    </div>
  )
}

export default SecureTransaction
