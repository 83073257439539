import React, { useContext } from 'react'
import logistic from '../assets/lgoistic.png';
import logisticIcon from '../assets/logistic-icon.png'
import { IoMdArrowRoundForward } from 'react-icons/io';
import { LanguageContext } from '../context/LanguageContext';
import { Link } from 'react-router-dom';
const LogisticCard = () => {
  const {language} = useContext(LanguageContext);

  const translations = {
    en:{
      h4:"Ultimum Logistics",
      p:"Andrés Chávez CEO of Ultimum ",
      h1:"Tkambio is like having an in-house banker at no extra cost. I get better exchange rates, and it simplifies receiving payments in the USA and making international purchases from Mexico.",
      btn:"Check Out  more of our Case Studies"
    },
    es:{
      h4:"Logística definitiva",
      p:"Andrés Chávez CEO de Ultimum",
      h1:"Tkambio es como tener un banquero interno sin costo extra. Obtengo mejores tipos de cambio y simplifica recibir pagos en USA y realizar compras internacionales desde México.",
      btn:"Vea más de nuestros estudios de caso"
    },
  };

  const t = translations[language];
  return (
    <div>
      <div className="max-w-[1200px] md:mx-auto mx-3 md:flex gap-9 my-28">
        <div className="md:w-[654px]">
          <div className="flex items-center">
            <img className='w-16' src={logisticIcon} alt="" />
            <h4 className="text-2xl font-semibold">{t.h4}</h4>
          </div>
          <p className="text-primary/40 font-medium text-lg mt-1">{t.p}</p>
          <h1 className="md:text-4xl text-2xl text-dark leading-[1.5] font-semibold mt-6">{t.h1}</h1>
          <button className=" mt-16 rounded-full underline text-white py-2 px-6 flex bg-primary gap-4 items-center my-6">
            <Link to={'/tkambio-credit'} className='flex items-center gap-2'>{t.btn} <IoMdArrowRoundForward/></Link>
          </button>
        </div>
        <div className={`md:w-[532px] order-first`}>
          <img src={logistic} alt="" />
        </div>
      </div>
    </div>
  )
}

export default LogisticCard
