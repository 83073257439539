import React, { useContext } from 'react'
import { LanguageContext } from '../context/LanguageContext'

import alerticon from '../assets/alert.png'
import automationicon from '../assets/automation.png'
import availbilityicon from '../assets/availbility.png'

const UnlockPower = () => {
    const {language} = useContext(LanguageContext);
    const translations = {
        en:{
            heading:"Unlock the Power of Tri-A",
            desc:"Stay informed with real-time alerts, automate your trades, and access the market 24/7.",

            alert:"ALERT",
            alertdesc:"Receive real-time notifications to stay on top of market movements.",
            automation:"AUTOMATION",
            automationdesc:"Streamline your trading with automated order placements.",
            availbility:"AVAILABILITY",
            availbilitydesc:"Access the market 24/7, ensuring you never miss an opportunity.",
        },
        es:{
            heading:"Desbloquea el poder de Tri-A",
            desc:"Manténgase informado con alertas en tiempo real, automatice sus operaciones y acceda al mercado las 24 horas, los 7 días de la semana.",

            alert:"ALERTA",
            alertdesc:"Reciba notificaciones en tiempo real para estar al tanto de los movimientos del mercado.",
            automation:"AUTOMATIZACIÓN",
            automationdesc:"Agilice sus operaciones con colocaciones de órdenes automatizadas.",
            availbility:"DISPONIBILIDAD",
            availbilitydesc:"Acceda al mercado las 24 horas del día, los 7 días de la semana, asegurándose de no perder nunca una oportunidad.",
        }
    }
    const t = translations[language];
  return (
    <div className='max-w-[1200px] mx-auto my-36'>
      <h1 className="text-center font-semibold text-4xl">{t.heading}</h1>
      <p className="text-textcolor/60 text-center text-lg font-medium my-3">{t.desc}</p>
      <div className='grid md:grid-cols-3 gap-8 my-9'>
        <div className='bg-[#F4F6FF] p-12 rounded-2xl shadow-lg flex flex-col items-center'>
            <img src={alerticon} alt="" />
            <h4 className="text-3xl text-dark font-semibold my-3">{t.alert}</h4>
            <p className="text-textcolor/60 font-semibold text-center">{t.alertdesc}</p>
        </div>
        <div className='bg-[#F4F6FF] p-12 rounded-2xl shadow-lg flex flex-col items-center'>
            <img src={automationicon} alt="" />
            <h4 className="text-3xl text-dark font-semibold my-3">{t.automation}</h4>
            <p className="text-textcolor/60 font-semibold text-center">{t.automationdesc}</p>
        </div>
        <div className='bg-[#F4F6FF] p-12 rounded-2xl shadow-lg flex flex-col items-center'>
            <img src={availbilityicon} alt="" />
            <h4 className="text-3xl text-dark font-semibold my-3">{t.availbility}</h4>
            <p className="text-textcolor/60 font-semibold text-center">{t.availbilitydesc}</p>
        </div>
      </div>
    </div>
  )
}

export default UnlockPower
