import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion"; // Import framer-motion
import logo from "../assets/logo-2.png";
import menu from "../assets/dropdown.png";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

import whatsappIcon from "../assets/whatsapp.png";
import english from "../assets/englishcircle.png";
import spanish from "../assets/spanishcircle.png";
import image1 from "../assets/cambiode.webp";
import image2 from "../assets/pagos.webp";
import image3 from "../assets/marketorder.webp";
import image4 from "../assets/forwards.webp";
import image5 from "../assets/cambiode.webp";

import { LanguageContext } from "../context/LanguageContext";
import { LuMenu } from "react-icons/lu";
import { IoClose } from "react-icons/io5";
import translations from "../languageContent/HeaderContent";



const dropdownImages = [image1, image2, image3, image4, image5];


const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  const { language, toggleLanguage } = useContext(LanguageContext);
  const t = translations[language];

  const navLinks = [
    { label: t.nosotros, link: "/about" },
    {
      label: t.solucionesDigitales,
      hasDropdown: true,
      dropdownItems: t.dropdownItems,
    },
    { label: t.blog, link: "/blog" },
    { label: t.testimonios, link: "/testimonials" },
    { label: t.preguntasFrecuentes, link: "/faqs" },
  ];

  const dropdownAnimation = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -10 },
  };

  return (
    <header className="bg-white w-full text-primary fixed z-50 shadow-lg">
      <div className={`max-w-[1300px] mx-auto flex justify-between items-center lg:py-0 py-4 px-6`}>
        {/* Logo */}
        <Link to="/">
          <img src={logo} alt="Logo" className="w-[120px]" />
        </Link>

        {/* Hamburger Icon */}
        <div className="lg:hidden flex items-center">
          <button onClick={toggleLanguage} className="text-sm text-primary rounded px-2 py-1">
              {language === "en" ? (
                <p className="flex items-center gap-1 text-lg uppercase">
                  <img alt="tkambios.us" className="w-[20px]" src={spanish} /> Es
                </p>
              ) : (
                <p className="flex items-center gap-1 text-lg uppercase">
                  <img alt="tkambios.us" className="w-[20px]" src={english} /> En
                </p>
              )}
            </button>
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className=" text-primary"
            >
            {isMenuOpen ? <IoClose  className="text-3xl"/> : <LuMenu  className="text-3xl"/>}
          </button>
        </div>


        {/* Mobile Menu */}
        <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="lg:hidden bg-white absolute top-[4.4rem] left-0 right-0 bg-blue shadow-lg"
          >
            <ul className="flex flex-col items-start p-4 space-y-2">
              {navLinks.map((item, idx) => (
                <li key={idx} className="py-2">
                  {item.hasDropdown ? (
                    <div>
                      <button
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        className="flex items-center gap-2"
                      >
                        {item.label}
                        {item.hasDropdown ? <MdOutlineKeyboardArrowDown className="text-xl" />:""}
                        <img src={menu} alt="Dropdown" className="w-4" />
                      </button>
                      <AnimatePresence>
                        {isDropdownOpen && (
                          <motion.ul
                          variants={dropdownAnimation}
                          initial="hidden"
                          animate="visible"
                          exit="exit"
                          className="mt-2 space-y-2 bg-white text-black rounded shadow-lg p-2"
                          >
                            {item.dropdownItems.map((drop, dropIdx) => (
                              <li key={dropIdx} className="px-2 py-1">
                                <Link to={drop.link} onClick={()=>setIsMenuOpen(false) || setIsDropdownOpen(false)}>
                                <p className="text-blue">{drop.name}</p>
                                <p className="text-gray-500 text-sm">
                                  {drop.desc}
                                </p>
                                </Link>
                              </li>
                            ))}
                          </motion.ul>
                        )}
                      </AnimatePresence>
                    </div>
                  ) : (
                    <Link to={item.link}>{item.label}</Link>
                  )}
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>

        {/* Desktop Menu */}
        <div className="hidden lg:flex items-center">
            <ul className="flex items-center">
              {navLinks.map((item, idx) => (
                <li
                  key={idx}
                  className={`relative hover:text-secondary duration-200 font-medium py-8 px-4 cursor-pointer ${item.hasDropdown ? "flex items-center" : ""}`}
                  onMouseEnter={() => item.hasDropdown && setIsDropdownOpen(true)}
                  onMouseLeave={() => item.hasDropdown && setIsDropdownOpen(false)}
                >
                  <Link to={item.link} className="hover:text-secondary">{item.label}</Link>
                  
                  {item.hasDropdown ? <MdOutlineKeyboardArrowDown className="text-xl" />:""}
                  {item.hasDropdown && isDropdownOpen && (
                    <motion.ul
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className="absolute right-[-35rem] top-[86px] bg-white text-black rounded shadow-lg p-3 w-[950px] flex items-start gap-4"
                    >
                      {t.dropdownItems.map((dropItem, dropIdx) => (
                        <li key={dropIdx} className="bg-cover hover:bg-gray-100">
                          <Link to={dropItem.link} className="text-sm">
                            <img className="max-w-[170px] h-[80px]" src={dropdownImages[dropIdx]} alt="" />
                            <p className="text-blue my-2">{dropItem.name}</p>
                            <p className="text-gray-500">{dropItem.desc}</p>
                          </Link>
                        </li>
                      ))}
                    </motion.ul>
                  )}
                </li>
              ))}
            </ul>
            {/* Language Toggle Button */}
            <button onClick={toggleLanguage} className="text-sm text-primary rounded px-2 py-1">
              {language === "en" ? (
                <p className="flex items-center gap-1 text-lg uppercase">
                  <img alt="tkambios.us" className="w-[20px]" src={spanish} /> Es
                </p>
              ) : (
                <p className="flex items-center gap-1 text-lg uppercase">
                  <img alt="tkambios.us" className="w-[20px]" src={english} /> En
                </p>
              )}
            </button>

            <img src={whatsappIcon} alt="WhatsApp" className="w-8 h-8 ml-4" />
            <Link to={process.env.REACT_APP_LOGIN} target="_blank">
              <button className=" py-2 px-6 text-center border-2 border-secondary ml-4 rounded-full hover:bg-secondary hover:text-white duration-300">
                {t.iniciarSesion}
              </button>
            </Link>
            <Link to={process.env.REACT_APP_REGISTER} target="_blank">
            {/* <Link to='/registration' target="_blank"> */}
              <button className=" py-2 px-6 text-center text-white hover:text-secondary bg-secondary mx-1 rounded-full hover:bg-transparent duration-300 border-2 border-seconbg-secondary">
                {t.registrarse}
              </button>
            </Link>
          </div>
      </div>
    </header>
  );
};

export default Header;
